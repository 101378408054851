import * as Sentry from '@sentry/browser';
import { zodValidationPlugin } from '@zodios/core';

import { inProduction } from 'env';

import type { ZodiosPlugin } from '@zodios/core';

const defaultValidationPlugin = zodValidationPlugin({
  validate: true,
  transform: true,
  sendDefaults: false,
});

// Wrap the default Zodios validation plugin and capture errors with Sentry
export const validationPlugin = {
  ...defaultValidationPlugin,
  response: async (api, config, response) => {
    try {
      const responseDefault = await defaultValidationPlugin.response?.(api, config, response);

      if (responseDefault) return responseDefault;
    } catch (error) {
      Sentry.captureException(error);

      // eslint-disable-next-line no-console -- Show Zod schema errors in development and staging
      if (!inProduction) console.error(error);
    }
    return response;
  },
} satisfies ZodiosPlugin;

// Wrap the default Zodios validation plugin and capture errors with Sentry
export function getValidationPlugin(name: string) {
  return {
    ...defaultValidationPlugin,
    response: async (api, config, response) => {
      try {
        const responseDefault = await defaultValidationPlugin.response?.(api, config, response);

        if (responseDefault) return responseDefault;
      } catch (error) {
        Sentry.captureException(error, { event_id: name });

        // eslint-disable-next-line no-console -- Show Zod schema errors in development and staging
        if (!inProduction) console.error(error);
      }
      return response;
    },
  } satisfies ZodiosPlugin;
}
