import { createGaEventRecord } from 'shared/lib/ga/utils';

/**
 * API Broadcast events
 */
const apiBroadcastGaEventRecords = createGaEventRecord({
  apiBroadcastDateRange: {
    name: 'apibroadcast_date_range_change',
    description: 'User is on the API Broadcast List page and changes the date range.',
  },
});

/**
 * Application-wide events
 */
const appGaEventRecords = createGaEventRecord({
  appTrialClose: {
    name: 'app_trial_close_click',
    description: 'User clicks to close the trial membership banner.',
  },
  appTrialUpgrade: {
    name: 'app_trial_upgrade_click',
    description: 'User clicks to upgrade their trial membership.',
    labelDescription: 'Location of the click.',
  },
  appSetupStart: {
    name: 'app_setup_start_connection',
    description:
      'User starts the connection process in Setup; note that this is an anonymous tracking event.',
  },
  appSidebar: {
    name: 'app_sidebar_click',
    description: 'User clicks on a menu item on the sidebar.',
    labelDescription: 'Name of the menu item.',
  },
  appSidebarCollapse: {
    name: 'app_sidebar_collapse',
    description: 'User clicks to collapse the sidebar.',
  },
  appBillingDashboard: {
    name: 'app_billing_dashboard_click',
    description: 'User clicks on the Billing Dashboard link.',
  },
});

/**
 * App Marketing events
 */
const appMarketingGaEventRecords = createGaEventRecord({
  appMarketingCard: {
    name: 'appmarketing_card_click',
    description: 'User is on the App Marketing page and clicks on a card.',
    labelDescription: 'Title of the card.',
  },
  appMarketingContact: {
    name: 'appmarketing_contact_click',
    description: 'User is on the App Marketing page and clicks Contact Us.',
    labelDescription: 'Title of the card the link appears in.',
  },
  appMarketingJpContact: {
    name: 'appmarketing_jp_contact_click',
    description: 'User is on the App Marketing page and clicks "To be released" (Japan Server).',
    labelDescription: 'Title of the card the link appears in.',
  },
  appMarketingThContact: {
    name: 'appmarketing_th_contact_click',
    description: 'User is on the App Marketing page and clicks Contact Us (Thailand channel).',
    labelDescription: 'Title of the card the link appears in.',
  },
  appMarketingLink: {
    name: 'appmarketing_link_click',
    description: 'User is on the App Marketing page and clicks Learn More.',
    labelDescription: 'Title of the card the link appears in.',
  },
  appMarketingSurveyCakeHelp: {
    name: 'appmarketing_survey_cake_help_click',
    description: 'User is on the Survey Cake List page and clicks How To Use.',
  },
});

/**
 * AutoReply events
 */
const autoReplyGaEventRecords = createGaEventRecord({
  autoReplyFormCreate: {
    name: 'autoreply_form_create_click',
    description: 'User is creating/editing an AutoReply and clicks Create.',
  },
  autoReplyFormNext: {
    name: 'autoreply_form_next_click',
    description: 'User is creating/editing an AutoReply and clicks Next.',
  },
  autoReplyFormSave: {
    name: 'autoreply_form_save_click',
    description: 'User is creating/editing an AutoReply and clicks Save.',
  },
  autoReplyFormDuplicate: {
    name: 'autoreply_form_duplicate_click',
    description: 'User is saving a duplicated AutoReply',
  },
  autoReplyDuplicated: {
    name: 'autoreply_duplicated',
    description: 'User created an AutoReply through duplicating an existing one.',
    valueDescription: 'AutoReply ID that was created.',
  },
  autoReplyListCreate: {
    name: 'autoreply_list_create_click',
    description: 'User is on the AutoReply List page and clicks Create.',
  },
  autoReplyListDateRange: {
    name: 'autoreply_list_date_range_change',
    description: 'User is on the AutoReply List page and changes the date range.',
  },
  autoReplyListExport: {
    name: 'autoreply_list_export_action',
    description: 'User is on the AutoReply List page and triggers the Export action.',
  },
  autoReplyListTooltipHover: {
    name: 'autoreply_list_tooltip_hover',
    description:
      'User is on the Auto Reply List view and hovers over the tooltip after the Auto reply name.',
  },
  autoReplyReportDateRange: {
    name: 'autoreply_report_date_range_change',
    description: 'User is on the AutoReply Report page and changes the date range.',
  },
  autoReplyReportExport: {
    name: 'autoreply_report_export_action',
    description: 'User is on the AutoReply Report page and triggers the Export action.',
  },
});

/**
 * Bindlink events
 */
const bindlinkGaEventRecords = createGaEventRecord({
  bindlinkFormEditMessage: {
    name: 'bindlink_form_edit_message',
    description: 'User is creating/editing a Bindlink and edits a message.',
  },
  bindlinkFormCreate: {
    name: 'bindlink_form_create_click',
    description: 'User is creating a Bindlink and clicks Save.',
  },
  bindlinkFormEdit: {
    name: 'bindlink_form_edit_click',
    description: 'User is editing a Bindlink and clicks Save.',
  },
  bindlinkFormNext: {
    name: 'bindlink_form_next_click',
    description: 'User is creating/editing a Bindlink and clicks Next.',
  },
  bindlinkListCreate: {
    name: 'bindlink_list_create_click',
    description: 'User is on the Bindlink List page and clicks Create.',
  },
});

/**
 * Broadcast events
 */
const broadcastGaEventRecords = createGaEventRecord({
  broadcastFormChannelSelect: {
    name: 'broadcast_form_channel_click',
    description: 'User is creating a Broadcast and selects a channel.',
    labelDescription: 'The selected channel.',
  },
  broadcastFormEditorOpen: {
    name: 'broadcast_form_editor_open',
    description:
      'User is creating/editing a Broadcast and clicks Next to open the editor in step 2.',
    labelDescription: 'The selected channel.',
  },
  broadcastFormSaveDraft: {
    name: 'broadcast_form_save_draft',
    description: 'User is creating a Broadcast and clicks Save Draft.',
    labelDescription: 'The selected channel.',
  },
  broadcastFormSaveSmartSend: {
    name: 'broadcast_form_save_smart_send',
    description: 'User is creating/editing a Broadcast and clicks Save on a Smart Send Broadcast.',
    labelDescription: 'A concatenated list of message modules used in this Broadcast.',
    valueDescription: 'Quick reply length.',
  },
  broadcastFormSaveScheduled: {
    name: 'broadcast_form_save_schedule',
    description: 'User is creating/editing a Broadcast and clicks Save on a Scheduled Broadcast.',
    labelDescription: 'A concatenated list of message modules used in this Broadcast.',
    valueDescription: 'Quick reply length.',
  },
  broadcastFormSaveSend: {
    name: 'broadcast_form_save_send',
    description: 'User is creating/editing a Broadcast and clicks Save on a standard Broadcast.',
    labelDescription: 'A concatenated list of message modules used in this Broadcast.',
    valueDescription: 'Quick reply length.',
  },
  broadcastFormSmsEditorShortLinkClick: {
    name: 'broadcast_form_sms_short_link_click',
    description:
      'User is creating/editing an SMS Broadcast and clicks the Short Link option on the toolbar.',
  },
  broadcastFormSmsEditorTagCreate: {
    name: 'broadcast_form_sms_tag_create',
    description:
      'User is creating/editing an SMS Broadcast, editing a short link, and creates a tag from the dropdown.',
  },
  broadcastFormSmsEditorTagSelect: {
    name: 'broadcast_form_sms_tag_select',
    description:
      'User is creating/editing an SMS Broadcast, editing a short link, and selects a tag from the dropdown.',
  },
  broadcastListChannelSelect: {
    name: 'broadcast_list_channel_click',
    description: 'User is on the Broadcast List page and clicks the channel picker.',
    labelDescription: 'The selected channel.',
  },
  broadcastListCreate: {
    name: 'broadcast_list_create_click',
    description: 'User is on the Broadcast List page and clicks Create.',
  },
  broadcastListExportAction: {
    name: 'broadcast_list_export_action',
    description: 'User is on the Broadcast List page and triggers the Export action.',
  },
  broadcastListExportDateRange: {
    name: 'broadcast_list_export_date_range',
    description:
      'User is in the Export modal on the Broadcast List page and changes the date range.',
  },
  broadcastListReport: {
    name: 'broadcast_list_report_click',
    description: 'User is on the Broadcast List page and clicks the Report button.',
  },
  broadcastReportDateRange: {
    name: 'broadcast_report_date_range_change',
    description:
      'User is in the Export modal on the Broadcast List page and changes the date range.',
  },
  broadcastReportExportAction: {
    name: 'broadcast_report_export_action',
    description: 'User is on the Broadcast Report page and triggers the Export action.',
  },
  broadcastReportExportDateRange: {
    name: 'broadcast_report_export_date_range',
    description:
      'User is in the Export modal on the Broadcast List page and changes the date range.',
  },
});

/**
 * Contacts (members) events
 */
const contactsGaEventRecords = createGaEventRecord({
  /** Note: this event can be removed anytime after 2024Q1; it was renamed */
  contactsDetailCdpTagsTab: {
    name: 'contacts_detail_tags_cdp_tab',
    description: 'User is on the Contact Profile page and clicks the CDP tags tab.',
  },
  /** Note: this event can be removed anytime after 2024Q1; it was renamed */
  contactsDetailMaacTagsTab: {
    name: 'contacts_detail_tags_maac_tab',
    description: 'User is on the Contact Profile page and clicks the MAAC tags tab.',
  },
  contactsImportLineError: {
    name: 'contacts_import_line_error',
    description:
      'User attempted to select the LINE API import method but their plan is not high enough to support this.',
  },
  contactsProfileOpen: {
    name: 'contacts_profile_open',
    description: 'User is on the Contact List page and clicks on a row to open a Contact Profile.',
  },
  contactsProfileCdpTagsTab: {
    name: 'contacts_profile_tags_cdp_tab',
    description: 'User is on the Contact Profile page and clicks the CDP tags tab.',
  },
  contactsProfileMaacTagsTab: {
    name: 'contacts_profile_tags_maac_tab',
    description: 'User is on the Contact Profile page and clicks the MAAC tags tab.',
  },
  contactsProfileGenderUpdate: {
    name: 'contacts_profile_gender_update',
    description: 'User is on the Contacts Profile and updates gender.',
  },
  contactsProfileEmailUpdate: {
    name: 'contacts_profile_email_update',
    description: 'User is on the Contacts Profile and updates email.',
  },
  contactsProfileMobileUpdate: {
    name: 'contacts_profile_mobile_update',
    description: 'User is on the Contacts Profile and updates mobile.',
  },
  contactsProfileLineIdCopied: {
    name: 'contacts_profile_line_id_copied',
    description: 'User is on the Contacts Profile and clicks on the copy Line ID button.',
  },
  contactsFilterAction: {
    name: 'contacts_filter_action',
    description: 'User is on the Contacts page and triggers the Filter action.',
    labelDescription: 'Name of the filter action triggered.',
  },
  contactsFilterClear: {
    name: 'contacts_filter_clear',
    description: 'User is on the Contacts page and clears the Filters.',
  },
  contactsFilterSelect: {
    name: 'contacts_filter_select',
    description: 'User is on the Contacts page and selects a filter.',
    labelDescription: 'Name of the filter selected.',
  },
  contactsListBatchTagAddModalOpen: {
    name: 'contacts_list_add_tag_modal_open',
    description: 'User is on the Contact List page and opens the batch Add Tags modal.',
  },
  contactsListBatchTagRemoveModalOpen: {
    name: 'contacts_list_remove_tag_modal_open',
    description: 'User is on the Contact List page and opens the batch Remove Tags modal.',
  },
  contactsListExportModalOpen: {
    name: 'contacts_list_export_modal_open',
    description: 'User is on the Contact List page and opens the Export modal.',
    labelDescription: 'Type of export being done.',
  },
  contactsListImportModalOpen: {
    name: 'contacts_list_import_modal_open',
    description: 'User is on the Contact List page and opens the Import modal.',
  },
});

/**
 * Dashboard events
 */
const dashboardGaEventRecords = createGaEventRecord({
  dashboardChartHover: {
    name: 'dashboard_chart_hover',
    description: 'User is on the Dashboard page and hovers over a chart.',
    labelDescription: 'Name of the chart the user hovers over.',
  },
  dashboardChartLegendItemToggle: {
    name: 'dashboard_chart_legend_item_toggle',
    description: 'User is on the Dashboard page and toggles a chart legend item.',
    labelDescription: 'Name of the chart and legend item the user toggles.',
  },
  dashboardDataCardTrendTooltip: {
    name: 'dashboard_data_card_trend_tooltip',
    description:
      'User is on the Dashboard page and hovers over a trend tooltip on a Data Card component.',
    labelDescription: 'Tracking ID of the page and card the tooltip appears on.',
  },
  dashboardDateInterval: {
    name: 'dashboard_date_interval_change',
    description: 'User is on the Dashboard module and changes the date range.',
    labelDescription: 'Name of the page the user is on.',
  },
  dashboardDateRange: {
    name: 'dashboard_date_range_change',
    description: 'User is on the Dashboard module and changes the date range.',
    labelDescription: 'Name of the page the user is on.',
  },
  dashboardNotificationsSendingRecordClick: {
    name: 'dashboard_notifications_link_click',
    description:
      'User is on the Dashboard module in the Notifications view and clicks on the Sending Record.',
  },
  dashboardTabChange: {
    name: 'dashboard_tab_change',
    description: 'User is on the Dashboard module and clicks to change tab.',
    labelDescription: 'Name of the element the user is interacting with.',
  },
  dashboardTableColumnSort: {
    name: 'dashboard_table_column_sort',
    description: 'User is on the Dashboard module and sorts a table column.',
    labelDescription: 'Name of the table the user sorts.',
  },
  dashboardTablePageSize: {
    name: 'dashboard_table_page_size',
    description: 'User is on the Dashboard module and changes the page size of a table.',
    labelDescription: 'Name of the table for which the user changes the page size.',
  },
});

/**
 * Deeplink events
 */
const deeplinkGaEventRecords = createGaEventRecord({
  deeplinkBatchCreate: {
    name: 'deeplink_batch_create_click',
    description: 'User is on the Deeplink Batch page and clicks Create.',
  },
  deeplinkBatchEdit: {
    name: 'deeplink_batch_edit_click',
    description: 'User is on the Deeplink Batch page and clicks Edit.',
  },
  deeplinkFormCreate: {
    name: 'deeplink_form_create_click',
    description: 'User is creating a Deeplink and clicks Save.',
  },
  deeplinkFormEdit: {
    name: 'deeplink_form_edit_click',
    description: 'User is editing a Deeplink and clicks Save.',
  },
  deeplinkFormEditMessage: {
    name: 'deeplink_form_edit_message',
    description: 'User is creating/editing a Deeplink and edits a message.',
  },
  deeplinkFormNext: {
    name: 'deeplink_form_next_click',
    description: 'User is creating/editing a Deeplink and clicks Next.',
  },
  deeplinkListCreate: {
    name: 'deeplink_list_create_click',
    description: 'User is on the Deeplink List page and clicks Create.',
  },
  deeplinkListExportAction: {
    name: 'deeplink_list_export_action',
    description: 'User is on the Deeplink List page and triggers the Export action.',
  },
  deeplinkListExportDateRange: {
    name: 'deeplink_list_export_date_range',
    description:
      'User is on the Deeplink List page, in the Export modal, and changes the date range.',
  },
  deeplinkListReport: {
    name: 'deeplink_list_report_click',
    description: 'User in on the Deeplink List page and clicks Report.',
  },
});

/**
 * DPM events
 */
const dpmGaEventRecords = createGaEventRecord({
  dpmFormSchedule: {
    name: 'dpm_form_schedule_click',
    description: 'User is creating/editing a DPM and clicks Schedule.',
    labelDescription: 'A list of product parameters attached to this message.',
  },
  dpmFormSend: {
    name: 'dpm_form_send_click',
    description: 'User is creating/editing a DPM and clicks Send.',
    labelDescription: 'A list of product parameters attached to this message.',
  },
  dpmListCreate: {
    name: 'dpm_list_create_click',
    description: 'User is on the DPM List page and clicks Create.',
  },
  dpmListExportAction: {
    name: 'dpm_list_export_action',
    description: 'User is on the DPM List page and triggers the Export action.',
  },
  dpmListExportDateRange: {
    name: 'dpm_list_export_date_range_change',
    description: 'User is on the DPM List page and changes the date range.',
  },
  dpmListReport: {
    name: 'dpm_list_report_click',
    description: 'User is on the DPM List page and clicks Report.',
  },
  dpmReportExportAction: {
    name: 'dpm_report_export_action',
    description: 'User is on the DPM Report page and triggers the Export action.',
  },
  dpmReportExportDateRange: {
    name: 'dpm_report_export_date_range_change',
    description: 'User is on the DPM Report page and changes the date range.',
  },
});

/**
 * Journey events
 */
const journeyGaEventRecords = createGaEventRecord({
  journeyCanvasControlsFitView: {
    name: 'journey_canvas_fit_view_click',
    description:
      'User is creating/editing a Journey and clicks on the fit view control on the canvas.',
  },
  journeyCanvasControlsZoomIn: {
    name: 'journey_canvas_zoom_in_click',
    description:
      'User is creating/editing a Journey and clicks on the zoom in control on the canvas.',
  },
  journeyCanvasControlsZoomOut: {
    name: 'journey_canvas_zoom_out_click',
    description:
      'User is creating/editing a Journey and clicks on the zoom out control on the canvas.',
  },
  journeyEditorPanelCancel: {
    name: 'journey_editor_panel_cancel_click',
    description: 'User is creating/editing a Journey node and clicks on the Cancel button.',
  },
  journeyEditorPanelDiscard: {
    name: 'journey_editor_panel_discard_click',
    description: 'User is creating/editing a Journey node and clicks on the Discard button.',
  },
  journeyEditorPanelDelete: {
    name: 'journey_editor_panel_delete_click',
    description: 'User is creating/editing a Journey node and clicks on the Delete button.',
  },
  journeyEditorPanelDeleteConfirm: {
    name: 'journey_editor_panel_delete_confirm',
    description: 'User is creating/editing a Journey node and confirms the delete action.',
  },
  journeyEditorPanelSave: {
    name: 'journey_editor_panel_save_click',
    description: 'User is creating/editing a Journey node and clicks on the Save button.',
  },
  journeyFormControlsExit: {
    name: 'journey_form_exit_click',
    description: 'User is creating/editing a Journey and clicks on the Exit button.',
  },
  journeyFormControlsSave: {
    name: 'journey_form_save_click',
    description: 'User is creating/editing a Journey and clicks on the Save button.',
  },
  journeyFormControlsSaveDraft: {
    name: 'journey_form_save_draft_click',
    description: 'User is creating/editing a Journey and clicks on the Save Draft button.',
  },
  journeyFormControlsNext: {
    name: 'journey_form_next_click',
    description: 'User is creating/editing a Journey and clicks on the Next button.',
  },
  journeyFormControlsPause: {
    name: 'journey_form_pause_click',
    description: 'User is creating/editing a Journey and clicks on the Pause button.',
  },
  journeyFormControlsTurnOn: {
    name: 'journey_form_turn_on_click',
    description: 'User is creating/editing a Journey and clicks on the Turn On button.',
  },
  journeyFormControlsPublish: {
    name: 'journey_form_publish_click',
    description: 'User is creating/editing a Journey and clicks on the Publish button.',
  },
  journeyFormControlsSchedule: {
    name: 'journey_form_schedule_click',
    description: 'User is creating/editing a Journey and clicks on the Schedule button.',
  },
  journeyFormControlsPrevious: {
    name: 'journey_form_previous_click',
    description: 'User is creating/editing a Journey and clicks on the Previous button.',
  },
  journeyFormStep1Activity: {
    name: 'journey_form_step_1_active_duration',
    description:
      'User is creating/editing a Journey; time spent on step 1 (basic settings) is recorded after they leave.',
    valueDescription: 'Number of seconds spent on this step.',
  },
  journeyFormStep2Activity: {
    name: 'journey_form_step_2_active_duration',
    description:
      'User is creating/editing a Journey; time spent on step 2 (canvas/editor) is recorded after they leave.',
    valueDescription: 'Number of seconds spent on this step.',
  },
  journeyListCreate: {
    name: 'journey_list_create_click',
    description: 'User is on the Journey List view and clicks on the Create Journey button.',
  },
  journeyListEdit: {
    name: 'journey_list_edit_click',
    description: 'User is on the Journey List view and clicks on the Edit Journey button.',
  },
  journeyListView: {
    name: 'journey_list_view_click',
    description: 'User is on the Journey List view and clicks on the View Journey button.',
  },
  journeyListDuplicate: {
    name: 'journey_list_duplicate_click',
    description: 'User is on the Journey List view and clicks on the Duplicate Journey button.',
  },
  journeyListDelete: {
    name: 'journey_list_delete_click',
    description: 'User is on the Journey List view and clicks on the Delete Journey button.',
  },
  journeyListDeleteConfirm: {
    name: 'journey_list_delete_confirm',
    description: 'User is on the Journey List view and confirms the Delete Journey action.',
  },
  journeyListReport: {
    name: 'journey_list_report_click',
    description: 'User is on the Journey List view and clicks on the Journey Report button.',
  },
  journeyListTurnOn: {
    name: 'journey_list_turn_on_click',
    description: 'User is on the Journey List view and clicks on the Turn On toggle.',
  },
  journeyListPause: {
    name: 'journey_list_pause_click',
    description: 'User is on the Journey List view and clicks on the Pause toggle.',
  },
  journeyPublishScheduleDuration: {
    name: 'journey_publish_schedule_duration',
    description: 'User publishes a Journey with both a scheduled start and end date.',
    valueDescription: 'Number of days between the start and end date of the journey.',
  },
  journeyPublishRepeatTimes: {
    name: 'journey_publish_repeat_times_count',
    description:
      'User publishes a Journey with a limitation on the number of times it will repeat.',
    valueDescription: 'Number of times the journey will repeat.',
  },
  journeyPublishMessageQuota: {
    name: 'journey_publish_message_quota_count',
    description: 'User publishes a Journey with a message quota.',
    valueDescription: 'Maximum number of messages that will be sent.',
  },
  journeyPublishSleepModeActive: {
    name: 'journey_publish_sleep_mode_active',
    description: 'User publishes a Journey with sleep mode active.',
  },
  journeyPublishSleepModeNotActive: {
    name: 'journey_publish_sleep_mode_inactive',
    description: 'User publishes a Journey with sleep mode not active.',
  },
  journeyReportCanvasFitView: {
    name: 'journey_report_fit_view_click',
    description:
      'User is on the Journey Report page and clicks on the fit view control on the canvas.',
  },
  journeyReportCanvasZoomIn: {
    name: 'journey_report_zoom_in_click',
    description:
      'User is on the Journey Report page and clicks on the zoom in control on the canvas.',
  },
  journeyReportCanvasZoomOut: {
    name: 'journey_report_zoom_out_click',
    description:
      'User is on the Journey Report page and clicks on the zoom out control on the canvas.',
  },
  journeyReportDateRange: {
    name: 'journey_report_date_range_change',
    description: 'User is on the Journey Report page and changes the date range.',
  },
  journeyReportSettingsTab: {
    name: 'journey_report_settings_tab_click',
    description: 'User is on the Journey Report page and clicks on the settings/canvas tabs.',
  },
});

/**
 * Message Editor events
 */
const messageEditorGaEventRecords = createGaEventRecord({
  messageEditorModuleAdd: {
    name: 'message_editor_module_add',
    description: 'User is in the LINE Message Editor and adds a new module.',
    labelDescription: 'Module type.',
  },
  messageEditorModuleDelete: {
    name: 'message_editor_module_delete',
    description: 'User is in the LINE Message Editor and deletes a module.',
    labelDescription: 'Module type.',
  },
  messageEditorCardEditorShareButton: {
    name: 'message_editor_card_share_click',
    description: 'User is in the LINE Message Editor and clicks on the Share button.',
  },
});

/**
 * Prize events
 */
const prizeGaEventRecords = createGaEventRecord({
  prizeFormCreate: {
    name: 'prize_form_create_click',
    description: 'User is on the Prize List view and clicks on the Create button.',
    labelDescription: 'Prize type and redemption mode.',
  },
  prizeFormUpdate: {
    name: 'prize_form_update_click',
    description: 'User is on the Prize List view and clicks on the Update button.',
    labelDescription: 'Prize type and redemption mode.',
  },
  prizeListCreate: {
    name: 'prize_list_create_click',
    description: 'User is on the Prize List view and clicks on the Create Prize button.',
  },
  prizeListEdit: {
    name: 'prize_list_edit_click',
    description: 'User is on the Prize List view and clicks on the Edit Prize button.',
  },
  prizeListReport: {
    name: 'prize_list_report_click',
    description: 'User is on the Prize List view and clicks on the Report button.',
  },
  prizeListUsageHelp: {
    name: 'prize_list_usage_help_click',
    description: 'User is on the Prize List view and clicks on the "How to Use" link.',
  },
  prizeReportAddDeleteCode: {
    name: 'prize_report_add_delete_code_click',
    description: 'User is on the Prize Report view and clicks on the Add or Delete Code button.',
  },
  prizeReportDeleteCode: {
    name: 'prize_report_delete_code_click',
    description: 'User is on the Prize Report view and clicks on the Delete Code button.',
  },
  prizeReportDeleteCodeConfirm: {
    name: 'prize_report_delete_code_confirm',
    description: 'User is on the Prize Report view and confirms the Delete Code action.',
  },
  prizeReportExport: {
    name: 'prize_report_export_click',
    description: 'User is on the Prize Report view and clicks on the Export button.',
  },
  prizeReportSearch: {
    name: 'prize_report_search_action',
    description: 'User is on the Prize Report view and triggers the Search action.',
    labelDescription: 'Search type.',
  },
});

/**
 * Referral events
 */
const referralGaEventRecords = createGaEventRecord({
  referralListCreate: {
    name: 'referral_list_create_click',
    description: 'User is on the Referral List view and clicks on the Create button.',
  },
  referralListEdit: {
    name: 'referral_list_edit_click',
    description: 'User is on the Referral List view and clicks on the Edit button.',
  },
  referralListReport: {
    name: 'referral_list_report_click',
    description: 'User is on the Referral List view and clicks on the Report button.',
  },
});

/**
 * Retarget events
 */
const retargetGaEventRecords = createGaEventRecord({
  retargetFormSchedule: {
    name: 'retarget_form_schedule_action',
    description: 'User is on the Retarget Form page and triggers the Schedule action.',
    labelDescription: 'List of modules applied to the Segment.',
  },
  retargetListCreate: {
    name: 'retarget_list_create_click',
    description: 'User is on the Retarget List view and clicks on the Create button.',
  },
  retargetListExportAction: {
    name: 'retarget_list_export_action',
    description: 'User is on the Retarget List view and triggers the Export action.',
  },
  retargetListExportDateRange: {
    name: 'retarget_list_export_date_range',
    description: 'User is on the Retarget List view and changes the date range.',
  },
  retargetListReport: {
    name: 'retarget_list_report_click',
    description: 'User is on the Retarget List view and clicks on the Report button.',
  },
  retargetReportExportAction: {
    name: 'retarget_report_export_action',
    description: 'User is on the Retarget Report page and triggers the Export action.',
  },
  retargetReportExportDateRange: {
    name: 'retarget_report_export_date_range',
    description: 'User is on the Retarget Report page and changes the date range.',
  },
});

/**
 * RichMenu events
 */
const richMenuGaEventRecords = createGaEventRecord({
  richMenuFormCreate: {
    name: 'rich_menu_form_create',
    description: 'User is on the Rich Menu Form page and creates a new Rich Menu.',
  },
  richMenuFormUpdate: {
    name: 'rich_menu_form_update',
    description: 'User is on the Rich Menu Form page and updates a Rich Menu.',
  },
  richMenuFormSchedule: {
    name: 'rich_menu_form_schedule',
    description: 'User is on the Rich Menu Form page and schedules a Rich Menu.',
  },
  richMenuListCreate: {
    name: 'rich_menu_list_create_click',
    description: 'User is on the Rich Menu List view and clicks on the Create button.',
  },
  richMenuListExportAction: {
    name: 'rich_menu_list_export_action',
    description: 'User is on the Rich Menu List view and triggers the Export action.',
  },
  richMenuListExportDateRange: {
    name: 'rich_menu_list_export_date_range',
    description: 'User is on the Rich Menu List view and changes the date range.',
  },
  richMenuListReport: {
    name: 'rich_menu_list_report_click',
    description: 'User is on the Rich Menu List view and clicks on the Report button.',
  },
  richMenuListTooltipHover: {
    name: 'rich_menu_list_tooltip_hover',
    description: 'User is on the Rich Menu List view and hovers over a tooltip.',
  },
  richMenuListSearch: {
    name: 'rich_menu_list_search_action',
    description: 'User is on the Rich Menu List view and triggers the Search action.',
  },
  richMenuReportDateRange: {
    name: 'rich_menu_report_date_range',
    description: 'User is on the Rich Menu Report page and changes the date range.',
  },
});

/**
 * Segment events
 */
const segmentGaEventRecords = createGaEventRecord({
  segmentFormAddGroup: {
    name: 'segment_form_filter_group_add',
    description: 'Users is on the Segment Form page and adds a new Group.',
  },
  segmentFormCreate: {
    name: 'segment_form_create',
    description: 'Users is on the Segment Form page and creates a new Segment.',
    labelDescription: 'Segment description, automatically generated.',
  },
  segmentFormCdpFilterSelect: {
    name: 'segment_form_cdp_filter_select',
    description: 'Users is on the Segment Form page and selects a new CDP Filter.',
    labelDescription: 'Type of Segment being selected.',
  },
  segmentFormExclusionAdd: {
    name: 'segment_form_exclusion_add',
    description: 'Users is on the Segment Form page and adds a Segment Exclusion.',
  },
  segmentFormExclusionSearch: {
    name: 'segment_form_exclusion_search',
    description:
      'Users is on the Segment Form page and searches for an item on the Segment Exclusion dropdown.',
  },
  segmentFormExclusionSelect: {
    name: 'segment_form_exclusion_select',
    description:
      'Users is on the Segment Form page and selects an item from the Segment Exclusion dropdown.',
  },
  segmentFormExclusionRemove: {
    name: 'segment_form_exclusion_remove',
    description: 'Users is on the Segment Form page and removes a Segment Exclusion.',
  },
  segmentFormMaacFilterSelect: {
    name: 'segment_form_maac_filter_select',
    description: 'Users is on the Segment Form page and selects a new Filter.',
    labelDescription: 'Type of Segment being selected.',
  },
  segmentFormUpdate: {
    name: 'segment_form_update',
    description: 'Users is on the Segment Form page and updates a Segment.',
    labelDescription: 'Segment description, automatically generated.',
  },
  segmentListCreate: {
    name: 'segment_list_create_click',
    description: 'User is on the Segment List page and clicks Create.',
  },
  segmentListCreateMutation: {
    name: 'segment_list_create_action',
    description: 'User is on the Segment List page and creates a new Segment.',
    labelDescription: 'Name of Segment being created.',
  },
  segmentListEdit: {
    name: 'segment_list_edit',
    description: 'User is on the Segment List page and clicks Edit.',
  },
  segmentListExport: {
    name: 'segment_list_export',
    description: 'User is on the Segment List page and clicks Export, opening the modal.',
  },
  segmentListExportAction: {
    name: 'segment_list_export_action',
    description: 'User is on the Segment List page and confirms Export.',
  },
  segmentListRefresh: {
    name: 'segment_list_update_click',
    description: 'User is on the Segment List page and clicks Update/Refresh, opening the modal.',
  },
  segmentListRefreshAction: {
    name: 'segment_list_update_action',
    description: 'User is on the Segment List page and confirms Update/Refresh.',
  },
  segmentListSend: {
    name: 'segment_list_send_click',
    description: 'User is on the Segment List page and clicks Send (Broadcast).',
    labelDescription: 'Title of the Segment.',
  },
  segmentListDelete: {
    name: 'segment_list_delete_click',
    description: 'User is on the Segment List page and clicks Delete.',
  },
  segmentListBroadcastNavigate: {
    name: 'segment_list_broadcast_click',
    description: 'User is on the Segment List page and clicks Broadcast.',
  },
});

/**
 * SMS+ events
 */
const smsPlusGaEventRecords = createGaEventRecord({
  smsPlusListDetail: {
    name: 'sms_plus_list_detail_click',
    description: 'User is on the SMS+ List view and clicks on the Detail button.',
  },
  smsPlusListSend: {
    name: 'sms_plus_list_send_click',
    description: 'User is on the SMS+ List view and clicks on the Send button.',
    valueDescription: 'ID of the message being sent.',
  },
  smsPlusPnpListDetail: {
    name: 'sms_plus_pnp_list_detail_click',
    description: 'User is on the PNP List view and clicks on the Detail button.',
  },
  smsPlusPnpListSend: {
    name: 'sms_plus_pnp_list_send_click',
    description: 'User is on the PNP List view and clicks on the Send button.',
    valueDescription: 'ID of the message being sent.',
  },
});

/**
 * Template library events
 */
const templateLibraryGaEventRecords = createGaEventRecord({
  templateLibraryListEdit: {
    name: 'template_library_list_edit_click',
    description: 'User is on the Template Library List view and clicks on the Edit button.',
  },
});

/**
 * Tracelink events
 */
const tracelinkGaEventRecords = createGaEventRecord({
  tracelinkFormSaveConfirm: {
    name: 'tracelink_form_save_confirm',
    description: 'User confirms save on the Tracelink form.',
  },
  tracelinkListCreate: {
    name: 'tracelink_list_create_click',
    description: 'User clicks Create Tracelink from the list view.',
  },
  tracelinkListDateRange: {
    name: 'tracelink_list_date_range_change',
    description: 'User changes the date range on the Tracelink list view.',
  },
  tracelinkListExport: {
    name: 'tracelink_list_export_action',
    description: 'User triggers an export from the Tracelink list view.',
  },
  tracelinkListReport: {
    name: 'tracelink_list_report_click',
    description: 'User clicks on a report from the Tracelink list view',
  },
});

/**
 * Widget events
 */
const widgetGaEventRecords = createGaEventRecord({
  widgetFormCreate: {
    name: 'widget_form_widget_create',
    description: 'User creates a new Widget.',
  },
  widgetFormUpdate: {
    name: 'widget_form_widget_update',
    description: 'User updates a Widget.',
  },
  widgetListCreate: {
    name: 'widget_list_create_click',
    description: 'User is on the Widget List page and clicks Create.',
  },
  widgetListDelete: {
    name: 'widget_list_delete_click',
    description: 'User is on the Widget List page and clicks Delete.',
  },
  widgetListEdit: {
    name: 'widget_list_edit_click',
    description: 'User is on the Widget List page and clicks Edit.',
  },
  widgetListReport: {
    name: 'widget_list_report_click',
    description: 'User is on the Widget List page and clicks Report.',
  },
});

/**
 * Two-factor authentication events
 */

const twoFactorAuthGaEventRecords = createGaEventRecord({
  twoFactorAuthEmailVerify: {
    name: '2fa_email_verify',
    description: 'User verifies their email address for two-factor authentication.',
  },
  twoFactorAuthEmailVerifyResend: {
    name: '2fa_email_verify_resend',
    description: 'User requests a new email verification for two-factor authentication.',
  },
  twoFactorAuthAuthenticatorVerify: {
    name: '2fa_auth_app_verify',
    description: 'User verifies their authenticator app for two-factor authentication.',
  },
  twoFactorAuthTryAnotherWay: {
    name: '2fa_try_another_way',
    description: 'User clicks to try another way for two-factor authentication.',
  },
  twoFactorAuthSwitchToEmail: {
    name: '2fa_switch_to_email',
    description: 'User switches the two-factor authentication method to email.',
  },
  twoFactorAuthSwitchToAuthenticator: {
    name: '2fa_switch_to_auth_app',
    description: 'User switches the two-factor authentication method to authenticator app.',
  },
  twoFactorAuthLoginWithAnotherAccount: {
    name: '2fa_login_with_another_account',
    description: 'User clicks to login with another account for two-factor authentication.',
  },
});

export const gaEventRecords = {
  ...apiBroadcastGaEventRecords,
  ...appGaEventRecords,
  ...appMarketingGaEventRecords,
  ...autoReplyGaEventRecords,
  ...bindlinkGaEventRecords,
  ...broadcastGaEventRecords,
  ...contactsGaEventRecords,
  ...dashboardGaEventRecords,
  ...deeplinkGaEventRecords,
  ...dpmGaEventRecords,
  ...journeyGaEventRecords,
  ...messageEditorGaEventRecords,
  ...prizeGaEventRecords,
  ...referralGaEventRecords,
  ...retargetGaEventRecords,
  ...richMenuGaEventRecords,
  ...segmentGaEventRecords,
  ...smsPlusGaEventRecords,
  ...twoFactorAuthGaEventRecords,
  ...templateLibraryGaEventRecords,
  ...tracelinkGaEventRecords,
  ...widgetGaEventRecords,
};
