import { memo, useContext } from 'react';
import { useTranslation } from 'react-i18next';

import { LME_ERROR_DRAFT_JS_PARSE_TO_RAW } from 'AppConstants';

import type { QuickReplyItemsProps } from 'components/LineMessageEditor/components/QuickReplyEditor/types';
import type { CardRichEditorIds } from 'components/LineMessageEditor/models/templateDataAndTypes/card';
import type {
  EditorDataType,
  EditorStateObject,
  State,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import {
  addQuickReplyToLastMessage,
  useParseToSaveData,
} from 'components/LineMessageEditor/utils/parseToSaveData';
import {
  createRichEditorIdTuple,
  generateRichEditorId,
} from 'components/LineMessageEditor/utils/richEditorId';
import { Text } from 'components/Typography/Text';
import { useCurrentChannel } from 'context/authContext';
import * as Validator from 'lib/validator';
import { errorMessageHelper } from 'lib/validator/helper/validatorHelpers';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { testIds } from 'shared/lib/cypress/testIds';
import { styled } from 'shared/utils/styled';
import { theme } from 'theme';

import { Context } from '../models';

import { ModuleButton } from './ModuleButton';

const LineModuleSelectorWrapper = styled.div`
  width: 100%;
  padding: 32px;
  box-sizing: border-box;
`;

interface LineModuleSelectorProps extends QuickReplyItemsProps {
  onAddTextModule?: (richEditorId: number) => void;
  onAddCardModule?: (richEditorIds: CardRichEditorIds) => void;
  onAddCarrouselModule?: (richEditorIds: CardRichEditorIds) => void;
  onAddImageModule?: () => void;
  onAddImageMapModule?: () => void;
  editorStateArray: EditorStateObject[];
  setEditorStateArray: (editorState: EditorStateObject) => void;
  onDataSave: (state: State & { errorMessage: string }) => void;
  allowedModules: LineFieldType[];
}

export const LineModuleSelector = memo(function LineModuleSelector({
  onAddTextModule,
  onAddCardModule,
  onAddCarrouselModule,
  onAddImageModule,
  onAddImageMapModule,
  editorStateArray,
  onDataSave,
  quickReplyItems,
  allowedModules,
}: LineModuleSelectorProps) {
  const { t } = useTranslation();

  const parseToSaveData = useParseToSaveData();
  const { language_code } = useCurrentChannel();
  const { dispatch, store } = useContext(Context);
  const validatorCtx = useContext(Validator.Context);

  const { message } = useMessage();

  const isDisabled = store.editorData.length >= 5;

  const validateShareableStatus = async () => {
    if (store.isShareable) {
      message.warning(t('message.shareableConflict.hint'));
    }
  };

  return (
    <LineModuleSelectorWrapper>
      <Text display="inline-block" color={theme.colors.neutral008}>
        {t('message.addModule', { count: store.editorData.length })}
      </Text>
      <br />
      <br />
      {allowedModules.includes(LineFieldType.Text) ? (
        <ModuleButton
          disable={isDisabled}
          moduleType={LineFieldType.Text}
          text={t('message.module.text')}
          onClick={() => {
            const richEditorId = generateRichEditorId();
            if (onAddTextModule) {
              onAddTextModule(richEditorId);
            }
            dispatch('addTextModule', {
              richEditorId,
            });
          }}
          data-test={testIds.editorModuleSelectorTextModule}
        />
      ) : null}
      {allowedModules.includes(LineFieldType.Image) ? (
        <ModuleButton
          data-test="module-selector-image-module"
          disable={isDisabled}
          moduleType={LineFieldType.Image}
          text={t('glossary.image')}
          onClick={() => {
            if (onAddImageModule) onAddImageModule();
            dispatch('addImageModule');
          }}
          openCountTrackable={true}
        />
      ) : null}
      {allowedModules.includes(LineFieldType.ImageCarousel) ? (
        <ModuleButton
          data-test="module-selector-image-carousel-module"
          disable={isDisabled}
          moduleType={LineFieldType.ImageCarousel}
          text={t('message.module.imageCarrousel')}
          onClick={() => {
            dispatch('addImageCarouselModule');
          }}
          openCountTrackable={true}
        />
      ) : null}
      {allowedModules.includes(LineFieldType.Card) ? (
        <ModuleButton
          data-test="module-selector-card-module"
          onClick={() => {
            const richEditorIds = createRichEditorIdTuple();
            if (onAddCardModule) {
              onAddCardModule(richEditorIds);
            }
            dispatch('addCardModule', { richEditorIds });
          }}
          disable={isDisabled}
          moduleType={LineFieldType.Card}
          text={t('message.module.card')}
          openCountTrackable={true}
        />
      ) : null}
      {allowedModules.includes(LineFieldType.Carrousel) ? (
        <ModuleButton
          data-test="module-selector-carousel-module"
          disable={isDisabled}
          moduleType={LineFieldType.Carrousel}
          text={t('message.module.carrousel')}
          onClick={() => {
            const richEditorIds = createRichEditorIdTuple();
            if (onAddCarrouselModule) {
              onAddCarrouselModule(richEditorIds);
            }
            dispatch('addCarrouselModule', { richEditorIds });
          }}
          openCountTrackable={true}
        />
      ) : null}
      {allowedModules.includes(LineFieldType.ImageMapFlex) ? (
        <ModuleButton
          data-test="module-selector-imagemap-module"
          disable={isDisabled}
          moduleType={LineFieldType.ImageMapFlex}
          text={t('message.module.imageMap')}
          openCountTrackable={true}
          onClick={() => {
            if (onAddImageMapModule) onAddImageMapModule();
            dispatch('addImageMapFlexModule');
            validateShareableStatus();
          }}
        />
      ) : null}
      {allowedModules.includes(LineFieldType.ImageMapCarousel) ? (
        <ModuleButton
          data-test="module-selector-imagemap-carousel-module"
          disable={isDisabled}
          moduleType={LineFieldType.ImageMapCarousel}
          text={t('message.module.imageMapCarousel')}
          openCountTrackable={true}
          onClick={() => {
            dispatch('addImageMapCarouselModule');
          }}
        />
      ) : null}
      {allowedModules.includes(LineFieldType.Video) ? (
        <ModuleButton
          data-test="module-selector-video-module"
          disable={isDisabled}
          onClick={() => {
            dispatch('addVideoModule');
            validateShareableStatus();
          }}
          moduleType={LineFieldType.Video}
          text={t('message.module.video')}
        />
      ) : null}
      {allowedModules.includes(LineFieldType.Prize) ? (
        <ModuleButton
          data-test="module-selector-prize-module"
          disable={isDisabled}
          onClick={() => {
            dispatch('addPrizeRedeemModule', { lang: language_code });
            validateShareableStatus();
          }}
          moduleType={LineFieldType.Prize}
          text={t('glossary.prize')}
          openCountTrackable={true}
        />
      ) : null}
      <ModuleButton
        data-test="line-editor-save-button"
        disable={false}
        onClick={() => {
          let finalEditorData = [] as EditorDataType[];
          const validateAll = validatorCtx.validate();

          try {
            finalEditorData = addQuickReplyToLastMessage(
              parseToSaveData(store, editorStateArray),
              quickReplyItems,
            );
            onDataSave({
              ...store,
              editorData: finalEditorData,
              errorMessage: errorMessageHelper(validateAll),
            });
          } catch (err) {
            onDataSave({
              ...store,
              editorData: finalEditorData,
              errorMessage: LME_ERROR_DRAFT_JS_PARSE_TO_RAW,
            });
          }
        }}
        id="LineMassagerEditorSaveButton"
        moduleType={LineFieldType.Video}
        text={t('common.noData')}
        style={{ display: 'none' }}
      />
    </LineModuleSelectorWrapper>
  );
});
