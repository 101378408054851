/* eslint-disable no-restricted-imports */
import { fc } from '@chatbotgang/etude/react/fc';
import AntModal from 'antd/es/modal';

import type { ModalProps } from 'antd/es/modal';
import type { OverrideComponentCssProps } from 'shared/utils/styled/types';

import { MotifIcon } from 'icons/motif';
import { styled } from 'shared/utils/styled';
import { overrideCss } from 'shared/utils/styled/override';
import { theme } from 'theme';

export const Modal = styled(AntModal)<OverrideComponentCssProps>`
  .ant-modal-content {
    border-radius: 4px;
  }
  .ant-modal-body {
    padding: 12px 0;
  }
  .ant-modal-footer {
    border-top: 0;
  }
  ${overrideCss}
`;

const WarningModalContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  text-align: center;

  > :first-child {
    display: flex;
    width: min(100%, 110px);
    align-items: center;
    justify-content: center;
    aspect-ratio: 1;

    > * {
      overflow: hidden;
      width: 100%;
      height: 100%;
      aspect-ratio: 1;
    }
  }
`;

export const WarningModal = fc((props: ModalProps) => {
  return (
    <Modal {...props}>
      <WarningModalContent>
        <MotifIcon
          un-i-motif="circle_caution_fill"
          style={{
            width: 110,
            height: 110,
            backgroundColor: theme.colors.yellow006,
          }}
        />
        <div>{props.children}</div>
      </WarningModalContent>
    </Modal>
  );
});
