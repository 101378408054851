import { object } from '@chatbotgang/etude/pitch-shifter/object';
import { string, toString } from '@chatbotgang/etude/pitch-shifter/string';
import { Space } from 'antd';
import { forwardRef, memo, useEffect, useImperativeHandle, useMemo } from 'react';
import { useAsync } from 'react-async';
import { useTranslation } from 'react-i18next';

import { MB } from 'AppConstants';

import type { IValidateResponse } from 'components/UploadPrizeCodes/types';
import type { TFunction, TOptions } from 'i18next';
import type { PrizeRedemptionMode } from 'modules/Prize/types';
import type { ChangeEvent, ComponentPropsWithRef } from 'react';

import { ExternalLink } from 'components/ExternalLink';
import { FormFieldDescription } from 'components/FormField';
import { Container as FileDisplayContainer } from 'components/Upload/Layout/FileDisplay';
import { validate } from 'components/UploadPrizeCodes/service';
import { useBotId } from 'context/authContext';
import { useHandler } from 'hooks/useEventCallback';
import { MotifIcon } from 'icons/motif';
import { isAxiosError } from 'lib/axios';
import { getRedemtionModeString } from 'modules/Prize/utils';
import { Button } from 'shared/components/Button';
import { UploadImageButton } from 'shared/components/Button/UploadImageButton';
import { InfoBox } from 'shared/components/InfoBox';
import { Text } from 'shared/components/Typography';
import { useMessage } from 'shared/hooks/ui/useMessage';
import { css } from 'shared/utils/styled/override';
import { theme } from 'theme';
import { toCamelKeys } from 'utils/object/toCamelKeys';

type ErrorName =
  | 'PrizeCodeImport001'
  | 'PrizeCodeImport002'
  | 'PrizeCodeImport003'
  | 'PrizeCodeImport004'
  | 'PrizeCodeImport005'
  | 'PrizeCodeImport006'
  | 'PrizeCodeImport007'
  | 'PrizeUrlImport001'
  | 'PrizeUrlImport002'
  | 'PrizeUrlImport003'
  | 'PrizeUrlImport004'
  | 'PrizeUrlImport005'
  | 'PrizeUrlImport006'
  | 'PrizeUrlImport007'
  | 'PrizeUrlImport008';

type ErrorTranslateTable = Record<ErrorName, string | ((o: TOptions) => string)>;

const prizeCodeImportErrorTable = (t: TFunction): ErrorTranslateTable => ({
  PrizeCodeImport001: t('prizeManagement.upload.validation.fileInvalidTypeReferToTemplate'),
  PrizeCodeImport002: t('prizeManagement.upload.validation.duplicatedCodes'),
  PrizeCodeImport003: ({ columnName }) =>
    t('prizeManagement.upload.validation.wrongSingleColumnName', { columnName }),
  PrizeCodeImport004: t('prizeManagement.upload.validation.removeDemoCode'),
  PrizeCodeImport005: t('prizeManagement.upload.validation.exceedLengthLimit'),
  PrizeCodeImport006: t('prizeManagement.upload.validation.code.emptySpaces'),
  PrizeCodeImport007: t('prizeManagement.upload.validation.blankCell.singleColumn'),
  PrizeUrlImport001: t('prizeManagement.upload.validation.fileInvalidTypeReferToTemplate'),
  PrizeUrlImport002: t('prizeManagement.upload.validation.duplicatedUrls', {
    type: t('prizeManagement.redemption.mode.uniqueUrl'),
  }),
  PrizeUrlImport003: ({ columnName }) => {
    const columns = toString(columnName).split(', ');
    return columns.length === 2
      ? t('prizeManagement.upload.validation.wrongTwoColumnName', {
          columnNameA: columns[0],
          columnNameB: columns[1],
        })
      : t('prizeManagement.upload.validation.wrongSingleColumnName', { columnName });
  },
  PrizeUrlImport004: ({ type }) =>
    type === 'unique_url'
      ? t('prizeManagement.upload.validation.removeDemo', {
          type: t('prizeManagement.redemption.mode.uniqueUrl'),
        })
      : t('prizeManagement.upload.validation.removeDemo.twoColumns'),
  PrizeUrlImport005: ({ type }) =>
    t('prizeManagement.upload.validation.emptySpaces', {
      type:
        type === 'unique_url'
          ? t('prizeManagement.redemption.mode.uniqueUrl')
          : t(`prizeManagement.redemption.mode.uniqueUrlWithCode`),
    }),
  PrizeUrlImport006: ({ type }) =>
    type === 'unique_url'
      ? t('prizeManagement.upload.validation.blankCell.singleColumn')
      : t('prizeManagement.upload.validation.blankCells.twoColumns'),
  PrizeUrlImport007: ({ type }) =>
    type === 'unique_url'
      ? t('prizeManagement.upload.validation.extraColumns.singleColumn')
      : t('prizeManagement.upload.validation.extraColumns.twoColumns'),
  PrizeUrlImport008: t('prizeManagement.upload.validation.urlFormat'),
});

type ErrorResponse = {
  name: string;
  detail: { type: string; column_name: string; file_format: string };
};

const errorTranslator = (
  errorResponse: ErrorResponse,
  errorTable: ErrorTranslateTable,
): string | undefined => {
  const trans =
    errorResponse.name in errorTable
      ? errorTable[errorResponse.name as keyof ErrorTranslateTable]
      : undefined;
  const result = typeof trans === 'function' ? trans(toCamelKeys(errorResponse.detail)) : trans;
  return result;
};

// eslint-disable-next-line react-refresh/only-export-components
export const uploadStatus = {
  error: -1,
  ready: 0,
  loading: 1,
  success: 2,
} as const;

type TypeUploadStatus = typeof uploadStatus;
export type UploadStatus = TypeUploadStatus[keyof TypeUploadStatus];

export interface UploadPrizeCodesProps extends Omit<ComponentPropsWithRef<'div'>, 'onChange'> {
  fileLimitDescription?: string;
  /** The file size limit number in "mb", eg. 3 means the limit is 3mb. Default value is `Infinity`. */
  fileSizeLimitInMb?: number;
  description?: string;
  templateDownloadLink: string;
  acceptedExtensions: string[];
  uploadButtonVisible?: boolean;
  isUniqueCode: boolean;
  redemptionMode: PrizeRedemptionMode;
  onChange?: (result: IValidateResponse['file_id']) => void;
  /** Status change callback of the upload */
  onStatusChange?: (status: UploadStatus) => void;
}

type UploadPrizeCodesRefAttributes = {
  /** Reset uploaded content */
  removeResult: () => void;
};

export const UploadPrizeCodes = memo(
  forwardRef<UploadPrizeCodesRefAttributes, UploadPrizeCodesProps>(function UploadPrizeCodes(
    {
      fileLimitDescription,
      fileSizeLimitInMb = Infinity,
      description,
      acceptedExtensions,
      templateDownloadLink,
      uploadButtonVisible = true,
      isUniqueCode,
      onChange,
      onStatusChange,
      redemptionMode,
      ...restProps
    }: UploadPrizeCodesProps,
    ref,
  ) {
    const { t } = useTranslation();
    const botId = useBotId();

    const { message } = useMessage();

    const fileSizeLimit = useMemo(() => fileSizeLimitInMb * MB, [fileSizeLimitInMb]);

    const acceptAttributes = useMemo(
      () => acceptedExtensions.map((ext) => `${ext}`).join(', '),
      [acceptedExtensions],
    );

    const { data, setData, run, isLoading, isFulfilled, error } = useAsync({
      deferFn: validate,
      onResolve: (data) => {
        onChange?.(data.file_id);
        onStatusChange?.(uploadStatus.success);
      },
      onReject: () => {
        onStatusChange?.(uploadStatus.error);
      },
    });

    useEffect(() => {
      if (isLoading) {
        onStatusChange?.(uploadStatus.loading);
      }
    }, [isLoading, onStatusChange]);

    const errorMessage = useMemo(() => {
      if (error) {
        if (isAxiosError(error) && error.response && error.response.data) {
          const translatedError = errorTranslator(
            object({
              name: string(),
              detail: object({ column_name: string(), type: string(), file_format: string() }),
            })(error.response.data),
            prizeCodeImportErrorTable(t),
          );

          return translatedError || t('common.unexpectedError');
        }
        return t('common.unexpectedError');
      }
      return null;
    }, [t, error]);

    const handleChange = useHandler((event: ChangeEvent<HTMLInputElement>) => {
      const {
        target: { files },
      } = event;

      if (!files || (files && files.length === 0)) return;
      const file = files[0];

      if (file.size > fileSizeLimit) {
        return message.error(
          t('common.fileSizeExceedWithSize', { size: fileSizeLimitInMb + 'mb' }),
        );
      }
      onStatusChange?.(uploadStatus.ready);
      run(botId, file, isUniqueCode, redemptionMode);
    });

    const removeResult = useHandler(() => {
      const emptyData = { file_id: '', count: 0 };
      setData(emptyData, () => {
        onChange?.(emptyData.file_id);
        onStatusChange?.(uploadStatus.ready);
      });
    });

    useImperativeHandle(ref, () => ({
      removeResult,
    }));

    return (
      <div {...restProps}>
        <Space direction="vertical">
          {fileLimitDescription ? (
            <FormFieldDescription>{fileLimitDescription}</FormFieldDescription>
          ) : null}
          <ExternalLink href={templateDownloadLink}>{t('common.downloadTemplate')}</ExternalLink>
          {uploadButtonVisible ? (
            <>
              {description ? <FormFieldDescription>{description}</FormFieldDescription> : null}
              {!data || data.file_id === '' || error || isLoading ? (
                <UploadImageButton
                  buttonProps={{
                    loading: isLoading,
                  }}
                  fileInputProps={{
                    disabled: isLoading,
                    accept: acceptAttributes,
                    onChange: handleChange,
                    // This fixes the showing of the native input
                    style: { display: isLoading ? 'none' : 'inline' },
                    'data-test': 'upload-label-file-input',
                  }}
                >
                  {isLoading ? t('prizeManagement.validating') : t('common.uploadFile')}
                </UploadImageButton>
              ) : null}
              {isLoading ? (
                <InfoBox status="warning" transparent={true}>
                  {t('prizeManagement.uploadCode.pleaseWaitForUpload')}
                </InfoBox>
              ) : null}
              {!isLoading && errorMessage ? (
                <InfoBox status="error" transparent={true}>
                  {errorMessage}
                </InfoBox>
              ) : null}
              {isFulfilled && data.file_id ? (
                <FileDisplayContainer
                  styledCss={css`
                    margin: 8px 0 16px;
                    justify-content: space-between;
                    min-height: auto;
                    width: 400px;
                    height: 32px;
                    box-sizing: content-box;
                  `}
                  data-test="prize-code-upload-fulfilled"
                >
                  <Text>
                    {t(`prizeManagement.redemption.uploadedSet`, {
                      count: data.count,
                      type: getRedemtionModeString(t, redemptionMode, data.count),
                    })}
                  </Text>
                  <Button
                    shape="circle"
                    type="text"
                    icon={<MotifIcon un-i-motif="bin" style={{ color: theme.colors.neutral007 }} />}
                    onClick={removeResult}
                  />
                </FileDisplayContainer>
              ) : null}
            </>
          ) : null}
        </Space>
      </div>
    );
  }),
);
