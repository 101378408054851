import { makeApi } from '@zodios/core';
import { z } from 'zod';

export const DayOfWeekEnum = {
  Sunday: 'sunday',
  Monday: 'monday',
  Tuesday: 'tuesday',
  Wednesday: 'wednesday',
  Thursday: 'thursday',
  Friday: 'friday',
  Saturday: 'saturday',
} as const;

const DayOfWeekSchema = z.nativeEnum(DayOfWeekEnum);
export type DayOfWeek = z.infer<typeof DayOfWeekSchema>;

export const daysOrder = {
  [DayOfWeekEnum.Monday]: 0,
  [DayOfWeekEnum.Tuesday]: 1,
  [DayOfWeekEnum.Wednesday]: 2,
  [DayOfWeekEnum.Thursday]: 3,
  [DayOfWeekEnum.Friday]: 4,
  [DayOfWeekEnum.Saturday]: 5,
  [DayOfWeekEnum.Sunday]: 6,
};

const OrganizationItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  uuid: z.string(),
  enable_two_factor: z.boolean(),
});

const OrganizationbusinessHoursSchema = z.record(
  DayOfWeekSchema,
  z.array(
    z.object({
      start_time: z.string(),
      end_time: z.string(),
    }),
  ),
);

export const organizationApi = makeApi([
  {
    alias: 'organization',
    method: 'get',
    path: '/organization/v1/organization/:organizationId/',
    response: OrganizationItemSchema,
  },
  {
    alias: 'organizationUpdate',
    method: 'patch',
    path: '/organization/v1/organization/:organizationId/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: OrganizationItemSchema.omit({ id: true }).partial(),
      },
    ],
    response: z.never(),
  },
  {
    alias: 'organizationBusinessHours',
    method: 'get',
    path: '/organization/v1/organization/:organizationId/business_hours/',
    response: OrganizationbusinessHoursSchema,
  },
  {
    alias: 'organizationBusinessHoursUpdate',
    method: 'put',
    path: '/organization/v1/organization/:organizationId/business_hours/',
    parameters: [
      {
        name: 'body',
        schema: OrganizationbusinessHoursSchema,
        type: 'Body',
      },
    ],
    response: OrganizationbusinessHoursSchema,
  },
  {
    alias: 'organizations',
    method: 'get',
    path: '/organization/v1/organization/',
    parameters: [
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: z.object({
      results: z.array(
        z.object({
          id: z.number().int(),
          name: z.string(),
          uuid: z.string(),
          enable_two_factor: z.boolean(),
        }),
      ),
    }),
  },
  {
    alias: 'switchOrganization',
    method: 'post',
    path: '/organization/v1/organization/switch/',
    parameters: [
      {
        name: 'body',
        schema: z.object({
          organization_id: z.number().int(),
        }),
        type: 'Body',
      },
    ],
    response: z.object({
      token: z.string(),
    }),
  },
]);

export type OrganizationbusinessHoursPayload = z.infer<typeof OrganizationbusinessHoursSchema>;
