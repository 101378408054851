import { z } from 'zod';

import { MessageSchema } from 'shared/components/Editor/LineMessageEditor/types';
import { ListResponseSchema, PageResponseSchema } from 'shared/models/common';
import {
  ReportMessageSchema,
  ReportShareSchema,
  ReportTotalSchema,
  ReportUrlSchema,
} from 'shared/models/report';

export const RetargetTabEnum = {
  All: 'all',
  Draft: 'draft',
  Scheduled: 'scheduled',
  Activate: 'activate',
  Finished: 'finished',
} as const;
export const RetargetTabEnumSchema = z.nativeEnum(RetargetTabEnum);
export type RetargetTab = z.infer<typeof RetargetTabEnumSchema>;

const RetargetMetricSchema = z.object({
  status: RetargetTabEnumSchema,
  count: z.number().int(),
});
export type RetargetMetric = z.infer<typeof RetargetMetricSchema>;

export const RetargetMetricsSchema = z.array(RetargetMetricSchema);
export type RetargetMetrics = z.infer<typeof RetargetMetricsSchema>;

export const RetargetMessageSchema = z.object({
  id: z.number().int(),
  bot: z.number().int(),
  name: z.string(),
  messages: z.array(MessageSchema),
  // TODO: this condition field is legacy property, we should deal with it in the next Retageting development cycle.
  condition: z.any(),
  start: z.string(),
  end: z.string(),
  status: RetargetTabEnumSchema,
});
export type RetargetMessage = z.infer<typeof RetargetMessageSchema>;

export const RetargetListItemSchema = RetargetMessageSchema.pick({
  id: true,
  name: true,
  status: true,
  start: true,
  end: true,
}).extend({
  count: z.number().int(),
  report: z.object({
    clicks: z.number().int(),
    transactions: z.number().int(),
    transaction_revenue: z.number(),
  }),
});
export type RetargetListItem = z.infer<typeof RetargetListItemSchema>;

export const RetargetListSchema = ListResponseSchema.extend({
  results: RetargetListItemSchema.array(),
});
export type RetargetList = z.infer<typeof RetargetListSchema>;

export const RetargetSettingSchema = z.object({
  id: z.number().int(),
  bot: z.number().int(),
  algorithm: z.string(),
  retargeting_interval: z.number(),
  enable_no_disturb: z.boolean(),
  no_disturb_interval: z.number(),
  enable_sleep_mode: z.boolean(),
  sleep_mode_start: z.string(),
  sleep_mode_end: z.string(),
});
export type RetargetSetting = z.infer<typeof RetargetSettingSchema>;

export const RetargetSettingListSchema = PageResponseSchema.extend({
  results: RetargetSettingSchema.array(),
});
export type RetargetSettingList = z.infer<typeof RetargetSettingListSchema>;

export const RetargetingPermissionErrorSchema = z.object({
  bot: z.array(
    z.enum([
      'This bot cannot edit retargeting setting',
      'This bot cannot edit retargeting message',
    ]),
  ),
});
export type RetargetingPermissionError = z.infer<typeof RetargetingPermissionErrorSchema>;

const ReportSchema = z.object({
  total: ReportTotalSchema,
  urls: z.array(ReportUrlSchema),
  messages: z.array(ReportMessageSchema).optional(),
  shares: z.array(ReportShareSchema).optional(),
});

export const RetargetReportSchema = z.object({
  id: z.number(),
  name: z.string(),
  status: RetargetTabEnumSchema,
  start: z.string(),
  end: z.string(),
  report: ReportSchema,
  count: z.number().int(),
});
export type RetargetReport = z.infer<typeof RetargetReportSchema>;
