import { makeApi } from '@zodios/core';
import { z } from 'zod';

import { ExportResponseSchema } from 'shared/models/common';
import {
  RetargetListSchema,
  RetargetMessageSchema,
  RetargetMetricsSchema,
  RetargetReportSchema,
  RetargetSettingListSchema,
  RetargetSettingSchema,
  RetargetTabEnumSchema,
} from 'shared/models/retarget';

export const retargetApi = makeApi([
  {
    alias: 'retargetList',
    method: 'get',
    path: '/line/v2/message/retarget/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: RetargetTabEnumSchema.optional(),
        type: 'Query',
      },
      {
        name: 'limit',
        schema: z.number().int().optional(),
        type: 'Query',
      },
      {
        name: 'cursor',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: RetargetListSchema,
  },
  {
    alias: 'retargetDeatil',
    method: 'get',
    path: '/line/v1/message/retarget/:retargetId/',
    parameters: [
      {
        name: 'retargetId',
        schema: z.number().int(),
        type: 'Path',
      },
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: RetargetMessageSchema,
  },
  {
    alias: 'retargetCreate',
    method: 'post',
    path: '/line/v1/message/retarget/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RetargetMessageSchema.omit({ id: true }),
      },
    ],
    response: RetargetMessageSchema,
  },
  {
    alias: 'retargetUpdate',
    method: 'put',
    path: '/line/v1/message/retarget/:retargetId/',
    parameters: [
      {
        name: 'retargetId',
        type: 'Path',
        schema: z.number().int(),
      },
      {
        name: 'body',
        type: 'Body',
        schema: RetargetMessageSchema.partial(),
      },
    ],
    response: RetargetMessageSchema,
  },
  {
    alias: 'retargetDelete',
    method: 'delete',
    path: '/line/v1/message/retarget/:retargetId/',
    parameters: [
      {
        name: 'retargetId',
        schema: z.number().int(),
        type: 'Path',
      },
    ],
    response: z.never(),
  },
  {
    alias: 'retargetSettings',
    method: 'get',
    path: '/line/v1/retarget/setting/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: RetargetSettingListSchema,
  },
  {
    alias: 'retargetSettingsCreate',
    method: 'post',
    path: '/line/v1/retarget/setting/',
    parameters: [
      {
        name: 'body',
        type: 'Body',
        schema: RetargetSettingSchema.omit({ id: true }),
      },
    ],
    response: RetargetSettingSchema,
  },
  {
    alias: 'retargetSettingsUpdate',
    method: 'put',
    path: '/line/v1/retarget/setting/:settingId/',
    parameters: [
      {
        name: 'settingId',
        type: 'Path',
        schema: z.number().int(),
      },
      {
        name: 'body',
        type: 'Body',
        schema: RetargetSettingSchema,
      },
    ],
    response: RetargetSettingSchema,
  },
  {
    alias: 'retargetMetrics',
    method: 'get',
    path: '/line/v1/message/retarget/metric/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: RetargetMetricsSchema,
  },
  {
    alias: 'retargetExport',
    method: 'post',
    path: '/line/v1/message/retarget/export/',
    parameters: [
      {
        name: 'body',
        schema: z
          .object({
            bot_id: z.number().int(),
            start_date: z.string(),
            end_date: z.string(),
            pk: z.number().int().optional(),
          })
          .strict(),
        type: 'Body',
      },
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'status',
        schema: RetargetTabEnumSchema.optional(),
        type: 'Query',
      },
    ],
    response: ExportResponseSchema.strict(),
  },
  {
    alias: 'retargetReport',
    method: 'get',
    path: '/line/v1/message/retarget/:retargetId/report/',
    parameters: [
      {
        name: 'start_date',
        schema: z.string(),
        type: 'Query',
      },
      {
        name: 'end_date',
        schema: z.string(),
        type: 'Query',
      },
    ],
    response: RetargetReportSchema,
  },
]);
