/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { object } from '@chatbotgang/etude/pitch-shifter/object';
import { create } from 'zustand';
import { persist } from 'zustand/middleware';

import type { JsonValue } from 'type-fest';
import type { PersistOptions } from 'zustand/middleware';

import { parseJson } from 'utils/parseJson';

/**
 * @param localStorageKey - The key to use when storing the state in localStorage.
 * @param parser - The parser to use when parsing the state from localStorage.
 * @param options - The options to pass to `zustand/middleware`.
 * @param forUser - [options.forUser] Remove if different user is signed in.
 * @example
 *
 * ```ts
 * const { useStore } = createZustandStorageStore('my-store', object({
 *   bar: string(),
 *   foo: string()
 * }));
 * ```
 */
export function createZustandStorageStore<Value extends JsonValue>(
  localStorageKey: string,
  parser: (v: JsonValue) => Value,
  options: {
    forUser?: boolean;
    storage?: Storage;
  } & Omit<
    PersistOptions<
      {
        value: Value;
        setValue: (value: Value | ((value: Value) => Value)) => void;
        clear: () => void;
      },
      Partial<{
        value: Value;
        setValue: (value: Value | ((value: Value) => Value)) => void;
        clear: () => void;
      }>
    >,
    // We use `storageKey` instead.
    | 'name'

    /**
     * We have overridden the `storage` option and integrated it with the
     * `parser`.
     */
    | 'storage'

    // deprecated
    | 'getStorage'
    | 'serialize'
    | 'deserialize'
  > = {},
) {
  type Store = {
    value: Value;
    setValue: (value: Value | ((value: Value) => Value)) => void;
    clear: () => void;
  };
  const deserialize = (
    raw: string,
  ): {
    state: {
      value: Value;
    };
  } => {
    const rawObj = parseJson(raw, null);
    return object({
      state: object({
        value: parser,
      }),
    })(rawObj);
  };
  const initialState = parser(null);
  const { storage = localStorage, ...restZustandPersistOptions } = options;
  const useStore = create<Store>()(
    persist(
      (set, get) => ({
        value: initialState,
        setValue(value: Value | ((value: Value) => Value)) {
          set({
            value:
              typeof value === 'function' ? (value as (value: Value) => Value)(get().value) : value,
          });
        },
        clear() {
          set({ value: initialState });
        },
      }),
      {
        name: localStorageKey,
        ...restZustandPersistOptions,
        storage: {
          getItem: (name) => {
            const str = storage.getItem(name) || '';
            return deserialize(str);
          },
          setItem: (name, newValue) => {
            const str = JSON.stringify(newValue);
            storage.setItem(name, str);
          },
          removeItem: (name) => storage.removeItem(name),
        },
      },
    ),
  );

  /**
   * @param event - The event to listen to.
   */
  function eventHandler(event: StorageEvent) {
    if (event.storageArea !== localStorage || event.key !== localStorageKey) return;
    const {
      state: { value },
    } = deserialize(event.newValue ?? '');
    useStore.setState({
      value,
    });
  }

  window.addEventListener('storage', eventHandler);
  /**
   * Must be called when the store is no longer needed.
   */
  function destroy() {
    window.removeEventListener('storage', eventHandler);
  }

  return {
    useStore,
    destroy,
  };
}
