// key used in local storage
const LOCAL_STORAGE_PREFIX = 'maac';
export const APP_LOADED_DATE = new Date();

function createLocalStorageKey<T extends string>(key: T): `${typeof LOCAL_STORAGE_PREFIX}-${T}` {
  return `${LOCAL_STORAGE_PREFIX}-${key}`;
}

export const LOCAL_STORAGE_TOKEN = 'user';
// TODO: Remove this key after the remember device migration is done
export const LOCAL_STORAGE_REMEMBER_TOKEN = 'rememberToken';
export const LOCAL_STORAGE_REMEMBER_DEVICE = createLocalStorageKey('rememberDevice');
export const LOCAL_STORAGE_JWT_PAYLOAD = 'jwtPayload';
export const LOCAL_STORAGE_LAST_CHANNEL = 'lastChannel';
export const LOCAL_STORAGE_USER_ID = 'userId';
export const LOCAL_STORAGE_ORG_ID = 'orgId';
export const LOCAL_STORAGE_LAST_CHANNEL_TYPE = 'lastChannelType';
export const LOCAL_STORAGE_SIDEBAR_COLLAPSED = 'sidebar_collapsed';
export const LOCAL_STORAGE_MEMBER_PROFILE_NOTES = 'memberProfileNotes';
export const LOCAL_STORAGE_FEATURE_FLAG = createLocalStorageKey('featureFlag');
export const SESSION_STORAGE_CHUNK_ERROR_REFRESHED = createLocalStorageKey('retryLazyRefreshed');
export const LOCAL_STORAGE_LAST_LOGIN_DOAMIN = createLocalStorageKey('lastLoginDomain');
export const LOCAL_STORAGE_LAST_LOGIN_METHOD = createLocalStorageKey('lastLoginMethod');
export const LOCAL_STORAGE_TWO_FACTOR_EMAIL_STORE = createLocalStorageKey('twoFactorEmailStore');

export const GLOBAL_SEARCH_PARAM_FEATURE_FLAG = 'featureFlag';

// key name of cookies
export const GENERAL_COOKIE_PREFIX = 'maac:';

// key name of headers
export const HEADER_BOT_ID = 'CrescLab-Line-Bot-Id';
export const HEADER_USER_ID = 'CrescLab-Line-User-Id';
export const HEADER_ORG_ID = 'CrescLab-Line-Org-Id';
export const WEBSOCKET_RETRY_LIMIT = 30;
export const LIST_WITH_DEPENDENCY_LIMIT = 20;
export const TESTER_LIMIT = 50;
export const MAAC_SCROLL_CONTENT_ID = 'maac-scroll-content';
export const IMAGE_MAP_CUSTOM_BLOCK_CLASSNAME = 'image-map-custom-block-editor';
export const KB = 1024;
export const MB = 1024 * KB;
export const GB = 1024 * MB;
export const EMPTY_STRING_PLACEHOLDER = '---';
export const DEFAULT_FALLBACK_TEXT = '－';
export const TEL_URL_PREFIX = 'tel:';
export const MAIL_URL_PREFIX = 'mailto:';
export const INPUT_NUMBER_PREVENT_KEY = ['=', '-', '.'];

/**
 * Date constants
 */
export const DATE_FORMAT = 'YYYY-MM-DD';
export const DATE_YEAR_MONTH_FORMAT = 'YYYY-MM';
export const TIME_FORMAT = 'HH:mm';
export const TIME_FORMAT_SECONDS = 'HH:mm:ss';
export const TIME_DISPLAY_FORMAT = 'h:mm a';
export const TIME_DISPLAY_FORMAT_JA = 'a h:mm';
export const DATE_TIME_FORMAT = 'YYYY-MM-DD HH:mm';
export const DATE_TIME_FORMAT_AM_PM_SLASH = 'YYYY/M/D h:mm A';
export const DATE_TIME_FORMAT_TIMEZONE = 'YYYY-MM-DD[T]HH:mm';
export const DATE_TIME_FORMAT_WITH_MINUTES = 'YYYY-MM-DD-HH-mm';
export const MILLISECONDS_IN_SECOND = 1000;
export const MILLISECONDS_IN_MINUTE = MILLISECONDS_IN_SECOND * 60;
export const MILLISECONDS_IN_HOUR = MILLISECONDS_IN_MINUTE * 60;
export const MILLISECONDS_IN_DAY = MILLISECONDS_IN_HOUR * 24;
export const HOURS_IN_DAY = 24;
export const DAYS_IN_WEEK = 7;
export const DAYS_IN_YEAR = 365;
export const DATE_BIG_YEAR_PLACEHOLDER = '9996';
export const COMPANY_FOUNDED_AT = '2017-05-17';

/**
 * Tag constants
 */
export const MAX_TAG_NAME_LENGTH = 50;
export const MAX_TAG_COUNT_LIMIT = 5;
// TODO: this old constant should be replaced by the one above
export const TAG_COUNT_LIMIT_DEFAULT = 10;

/**
 * Input limits
 */
export const MAX_NUMBER_INPUT_LIMIT = 999;

/* spell-checker: disable */
export const MIME_TYPE = {
  csv: 'text/csv',
  excel_2003: 'application/vnd.ms-excel',
  excel_2007: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
};
/* spell-checker: enable */

/**
 * Line Message Editor errors
 */
export const LME_ERROR_DRAFT_JS_PARSE_TO_RAW = 'Editor Draft Parse to RAW Error';
export const LME_ERROR_DRAG_MODE_ON = 'please turn off “reorder messages”';
export const LME_ERROR_SHAREABLE_CONFLICT_EXISTS = 'please fix the conflict';

export const OPEN_COUNT_FEATURE_LAUNCH_DATE = '2021-03-29';

export enum Url {
  surveyCakeLearnMore = 'https://docs.google.com/presentation/d/e/2PACX-1vQVxAb6xahkSs8VPYgjs2Lg3-nbq9jJFWpqRgmY0xyX-Bh0YKEVKzXrw7k_X6pZxYWuNYHgVP2vov7q/pub?start=false&loop=false&delayms=30000&slide=id.ge6589af6a4_0_0',
}
// TODO: use design system theme
export const ICON_COLOR = 'rgba(0,0,0,.25)';

// # region HTTP Status Code

export const HTTP_STATUS_OK = 200;
// # endregion

/**
 * Classnames applied to major UI components to control click outside functionality
 */
export const MAAC_HEADER_ANNOUNCEKIT_CLASSNAME = 'maac-header-announcekit';
export const MAAC_HEADER_NOTIFICATIONS_CLASSNAME = 'maac-header-notifications';
export const MAAC_HEADER_USER_PROFILE_CLASSNAME = 'maac-header-user-profile';
export const MAAC_SIDEBAR_CLASSNAME = 'maac-sidebar';
