import { toNumber } from '@chatbotgang/etude/pitch-shifter/number';
import { safeString } from '@chatbotgang/etude/string/safeString';
import { convertToRaw } from 'draft-js';

import type { URIAction } from '@line/bot-sdk';
import type { QuickReplyItems } from 'components/LineMessageEditor/components/QuickReplyEditor';
import type { CustomUriAction } from 'components/LineMessageEditor/models/templateDataAndTypes/imageCarousel';
import type { RichTextParameter } from 'components/LineMessageEditor/models/templateDataAndTypes/richtext';
import type {
  EditorDataType,
  EditorStateObject,
  ImageMapActionUrlType,
  ImageMapCustomParameterType,
  Parameter,
  State,
  TextDataParameterCustom,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';

import { parseImageMapToFlex } from 'components/LineMessageEditor/components/ImageMapFlexEditor/parseImageMapToFlex';
import { isImageCarouselDataRule } from 'components/LineMessageEditor/models/templateDataAndTypes/imageCarousel';
import {
  ImageMapCarouselActions,
  isImageMapCarouselCustomUriAction,
  isImageMapCarouselUriAction,
} from 'components/LineMessageEditor/models/templateDataAndTypes/imageMapCarousel';
import { LineFieldType } from 'components/LineMessageEditor/models/templateDataAndTypes/LineFieldType';
import { createTextFormat } from 'components/LineMessageEditor/models/templateDataAndTypes/richtext';
import {
  ImageMapActions,
  isCardDataRule,
  isCarrouselDataRule,
  isImageDataRule,
  isImageMapCarouselDataRule,
  isImageMapDataActionUrlRule,
  isImageMapDataRule,
  isLinkParameter,
  isRichTextCustomParameter,
  isTextDataRule,
} from 'components/LineMessageEditor/models/templateDataAndTypes/types';
import { parseWidthAndHeight } from 'components/LineMessageEditor/utils/imageMapCarousel';
import { logError } from 'lib/logger';
import { fromUtf16toUnixText } from 'utils/string/textUtils';

import {
  preProcessCardDataForFinalSaveData,
  sanitizeHeroImage,
  sanitizeRichTextParameters,
} from './processCardData';
import { composeKey, isMappingKey } from './transformKey';

type ParseToSaveDataType = (
  store: State,
  editorStateArray: EditorStateObject[],
) => EditorDataType[];

/**
 * A hook to get a function of parsing state to API payload data.
 */
export const useParseToSaveData = (): ParseToSaveDataType => {
  return function parseWithParamsToSaveData(
    store: State,
    editorStateArray: EditorStateObject[],
  ): EditorDataType[] {
    const finalObject = JSON.parse(JSON.stringify(store.editorData)) as EditorDataType[];

    const finalEditorData = finalObject.map((message) => {
      if (isTextDataRule(message)) {
        // Get draft-js data
        const finalContent =
          editorStateArray[
            editorStateArray.findIndex((d) => d.id === message.format.richEditorId)
          ].editorState.getCurrentContent();

        // Turn draft-js data to pure text string
        let finalText = fromUtf16toUnixText(
          finalContent
            .getBlocksAsArray()
            .map((block) => block.getText())
            .reduce((pre, now) => pre + '\n' + now),
        );

        let rowData = '';

        // We keep draft-js data in "rowData" and send it to backend, so next time we can fully recover them.
        try {
          rowData = JSON.stringify(convertToRaw(finalContent));
        } catch (error) {
          logError(error);
        }

        // Turn "<$var:mappingKey>" to "<$var:key>", so backends can replace the key then.
        (message.parameters as RichTextParameter[])
          .filter((d) => isRichTextCustomParameter(d))
          .forEach((d) => {
            const stringToGoIntoTheRegex = (d as TextDataParameterCustom).mappingKey;
            const regex = new RegExp(stringToGoIntoTheRegex, 'g');
            finalText = finalText.replace(regex, (d as TextDataParameterCustom).key);
          });

        return {
          ...message,
          data: { text: safeString(finalText) },
          format: createTextFormat({ ...message.format, rowData }),
          parameters: message.parameters
            .filter((d) => {
              return finalText.indexOf(composeKey(d.key)) !== -1;
            })
            .filter((d) => d.key !== ''),
        };
      } else if (isImageMapDataRule(message)) {
        const customParameters = message.parameters.filter(
          (d) =>
            (d as ImageMapCustomParameterType).mappingKey !== undefined &&
            (d as ImageMapCustomParameterType).mappingKey.indexOf('custom') !== -1,
        ) as ImageMapCustomParameterType[];
        const finalActions = message.data.actions.map((d) => {
          if (
            d.type === ImageMapActions.uri &&
            (d as ImageMapActionUrlType).linkUri.indexOf('custom') !== -1
          ) {
            const customParameter = customParameters.filter(
              (c) => (d as ImageMapActionUrlType).linkUri === `<$var:${c.mappingKey}>`,
            );
            if (customParameter.length > 0) {
              return {
                ...d,
                linkUri: (d as ImageMapActionUrlType).linkUri.replace(
                  customParameter[0].mappingKey,
                  customParameter[0].key,
                ),
              };
            } else return d;
          }
          return d;
        });
        const result = {
          ...message,
          data: { ...message.data, actions: finalActions },
          parameters: message.parameters.filter((d) => d.key !== ''),
        };

        if (result?.format?.isFlex !== false) return parseImageMapToFlex(result);
        else return result;
      } else if (isImageMapCarouselDataRule(message)) {
        // Find the smallest aspect ratio value from all imagemap carousel images
        const smallestAspectRatio = Math.min(
          ...message.data.contents.contents.map((imageMap) => {
            const [imageMapImage] = imageMap.body.contents;
            const { aspectRatioValue } = parseWidthAndHeight(imageMapImage.aspectRatio || '1:1');
            return aspectRatioValue;
          }),
        );

        message.data.contents.contents = message.data.contents.contents.map((imageMap) => {
          const [imageMapImage, ...imageMapActionBlocks] = imageMap.body.contents;

          // Calculate the resize rate based on the smallest aspect ratio value
          const { aspectRatioValue } = parseWidthAndHeight(imageMapImage.aspectRatio || '1:1');
          const resizeRate = smallestAspectRatio / aspectRatioValue;

          imageMap.body.contents = [
            imageMapImage,
            ...imageMapActionBlocks.map(({ action, height, offsetTop, ...rest }) => {
              /*
               * Resize the height and offsetTop based on the resize rate
               * LINE stretch the container (offset base) of each image map carousel image to fit the tallest one, so we need to resize the action blocks to fit the new height
               * Should convert them back to normal value for UI after receiving the data from backend
               */
              const resizedBlock = {
                ...rest,
                height: height
                  ? `${(
                      toNumber(height.replace('%', ''), { defaultValue: 0 }) * resizeRate
                    ).toFixed(2)}%`
                  : undefined,
                offsetTop: offsetTop
                  ? `${(
                      toNumber(offsetTop.replace('%', ''), { defaultValue: 0 }) * resizeRate
                    ).toFixed(2)}%`
                  : undefined,
              };

              if (isImageMapCarouselUriAction(action)) {
                const linkParameter = message.parameters.find((p) => p.key === action.key);

                if (linkParameter) {
                  return {
                    ...resizedBlock,
                    action: {
                      ...action,
                      uri: composeKey(linkParameter.key),
                    },
                  };
                }

                return { ...resizedBlock, action };
              } else if (isImageMapCarouselCustomUriAction(action)) {
                const customUriParameter = message.parameters.find(
                  (p) => p.mappingKey === action.key,
                );

                if (customUriParameter) {
                  return {
                    ...resizedBlock,
                    action: {
                      ...action,
                      // Convert from "customUri" back to "uri" since LINE doesn't accept "customUri"
                      type: ImageMapCarouselActions.uri,
                      uri: composeKey(customUriParameter.key),
                    },
                  };
                }

                return { ...resizedBlock, action };
              } else {
                return { ...resizedBlock, action };
              }
            }),
          ];

          return imageMap;
        });

        return {
          ...message,
          // This is used in UI only. Reset all to "1".
          activeBlocks: Array(message.data.contents.contents.length).fill(1),
        };
      } else if (isCardDataRule(message)) {
        const customHero = message.parameters.find((p) => isMappingKey('customHero', p.mappingKey));
        const uris = message.parameters.filter(
          (p) =>
            isMappingKey('standardLink', p.key) ||
            isMappingKey('customUri', p.mappingKey) ||
            isMappingKey('shareButton', p.key),
        );
        const finalFooterContents = message.data.contents.footer.contents.map((content) => {
          if (content.action.type === 'uri') {
            // Replace Link Parameter and/or Custom URI Parameter uri with <$var:key>
            const parameter = uris.find(
              (u) => u.key === content.key || u.mappingKey === content.key,
            );
            if (parameter) {
              content.action.uri = composeKey(parameter.key);
            }
          }
          return content;
        });

        // Replace hero image url with <$var:key>
        if (customHero && message.data.contents.hero?.key) {
          message.data.contents.hero.url = composeKey(customHero.key);
        }
        message.data = sanitizeHeroImage(message.data);
        message.data.contents.footer.contents = finalFooterContents;
        message.data = preProcessCardDataForFinalSaveData(
          message.data,
          message.parameters,
          editorStateArray,
        );
        message.parameters = sanitizeRichTextParameters(
          message.data.contents.body.contents,
          message.parameters,
        );
        return message;
      } else if (isCarrouselDataRule(message)) {
        const customParameter = message.parameters.filter((d) => d.mappingKey !== undefined);
        const customHeroes = customParameter.filter((p) =>
          isMappingKey('customHero', p.mappingKey),
        );
        const uris = message.parameters.filter(
          (p) =>
            isMappingKey('standardLink', p.key) ||
            isMappingKey('customUri', p.mappingKey) ||
            isMappingKey('shareButton', p.key),
        );
        message.data.contents.forEach((cardMessage, index) => {
          const finalFooterContents = cardMessage.contents.footer.contents.map((content) => {
            // Replace Link Parameter and/or Custom URI Parameter uri with <$var:key>
            if (content.action.type === 'uri') {
              const parameter = uris.find(
                (u) => u.key === content.key || u.mappingKey === content.key,
              );
              if (parameter) {
                content.action.uri = composeKey(parameter.key);
              }
            }
            return content;
          });

          message.data.contents = message.data.contents.map((card) => {
            const mappedCustomHero = customHeroes.find((h) =>
              isMappingKey('customHero', h.mappingKey, card.contents.hero?.key),
            );
            if (mappedCustomHero?.key && card.contents.hero?.key) {
              // Replace hero image urls with mapped <$var:key>
              card.contents.hero.url = composeKey(mappedCustomHero.key);
              return card;
            } else {
              // Sanitize hero image if empty
              return sanitizeHeroImage(card);
            }
          });
          message.data.contents[index].contents.footer.contents = finalFooterContents;
          message.data.contents[index] = preProcessCardDataForFinalSaveData(
            cardMessage,
            message.parameters,
            editorStateArray,
          );
        });
        message.parameters = sanitizeRichTextParameters(
          message.data.contents.flatMap((cardData) => cardData.contents.body.contents),
          message.parameters,
        );
        return message;
      } else if (isImageCarouselDataRule(message)) {
        // Replace image urls with mapped <$var:key>
        const customImages = message.parameters.filter((p) =>
          isMappingKey('customImage', p.mappingKey),
        );
        // Replace image action area with mapped <$var:key>
        const customUris = message.parameters.filter((p) =>
          isMappingKey('customUri', p.mappingKey),
        );
        const customLinks = message.parameters.filter((p) => isLinkParameter(p));
        message.data.contents = message.data.contents.map((content) => {
          const mappedCustomImage = customImages.find(
            (img) => img.mappingKey === content.hero.contents[0].key,
          );
          const mappedCustomUri = customUris.find(
            (uri) => uri.mappingKey === (content.hero.action as CustomUriAction)?.key,
          );
          const mappedLink = customLinks.find(
            (link) => link.key === (content.hero.action as URIAction)?.uri,
          );
          if (mappedCustomImage?.key) {
            content.hero.contents[0].url = composeKey(mappedCustomImage.key);
          }
          if (mappedCustomUri?.key) {
            (content.hero.action as CustomUriAction).uri = composeKey(mappedCustomUri.key);
            // Convert to LINE supported type of `uri` instead of keeping the type `customUri`.
            // Otherwise, the message will be invalid from LINE's end.
            (content.hero.action as URIAction).type = 'uri';
          }
          if (mappedLink?.key) {
            (content.hero.action as URIAction).uri = composeKey(mappedLink.key);
          }
          return content;
        });
        return message;
      } else if (isImageDataRule(message)) {
        // Replace image url with mapped <$var:key>
        const customImage = message.parameters.find((p) =>
          isMappingKey('customImage', p.mappingKey),
        );
        if (customImage?.key) {
          message.data.content_url = composeKey(customImage.key);
        }
        return message;
      } else return message;
    });
    return cleanOldUrlParameter(finalEditorData);
  };
};

export const addQuickReplyToLastMessage = (
  messages: EditorDataType[],
  quickReplyItems: QuickReplyItems,
): EditorDataType[] => {
  return messages.map((message, index) => {
    if (index === messages.length - 1 && quickReplyItems.length > 0) {
      return { ...message, quick_reply: { items: quickReplyItems } } as EditorDataType;
    }
    if (index === messages.length - 1 && quickReplyItems.length === 0) {
      const finalMessage = JSON.parse(JSON.stringify(message));
      if ((finalMessage as EditorDataType).module_id === LineFieldType.SMS) {
        // suppress lint for sms message does not contain quick replies
        return finalMessage;
      }
      delete finalMessage.quick_reply;
      return finalMessage;
    }
    return message;
  });
};

function cleanOldUrlParameter(messages: EditorDataType[]): EditorDataType[] {
  try {
    return (
      messages
        // clone the array, so that we don't modify the original
        .map((d) => JSON.parse(JSON.stringify(d)) as EditorDataType)
        .map((message) => {
          const removeList: string[] = [];
          if (isTextDataRule(message)) {
            // RichText的無用URL parameter替換
            message.parameters.forEach((d) => {
              if (d.key.indexOf('url') !== -1) {
                if (message.data.text.indexOf('<$var:' + d.key + '>') === -1)
                  removeList.push(d.key);
              }
            });
            message.parameters = removeOldParameter(message.parameters, removeList);
          } else if (isImageMapDataRule(message)) {
            // Filter the unused URL parameters
            message.parameters.forEach((d) => {
              if (isLinkParameter(d)) {
                const index = message.data.actions
                  .filter((f) => isImageMapDataActionUrlRule(f))
                  .findIndex(
                    (f) => (f as ImageMapActionUrlType).linkUri === '<$var:' + d.key + '>',
                  );
                if (index === -1) removeList.push(d.key);
              }
            });
            message.parameters = removeOldParameter(message.parameters, removeList);
            message.actions = [];
          } else if (isCardDataRule(message)) {
            message.actions = [];
            // TODO
          } else if (isCarrouselDataRule(message)) {
            message.actions = [];
            // TODO
          }
          return message;
        })
    );
  } catch {
    return messages;
  }
}

function removeOldParameter(parameters: Array<Parameter>, removeList: string[]) {
  return parameters.filter((d) => {
    return !removeList.some((f) => f === d.key);
  });
}
