import { availableLocales } from 'config/availableLocales';
import { defineConfig } from 'features/featureFlag/defineConfig';

const debugFeatureFlags = defineConfig({
  debugDivider: {
    type: 'divider',
    label: 'Debug Tools',
  },
  'show-feature-flag-icon': {
    label: 'Show feature flag icon',
    type: 'toggle',
  },
  'quick-i18n': {
    label: 'Quick switch language',
    type: 'singleSelect',
    options: availableLocales.map(({ code, displayName }) => ({ label: displayName, value: code })),
  },
  'enabled-server-region': {
    label: 'Update server region',
    type: 'singleSelect',
    options: [
      {
        value: 'global',
        label: 'Global',
      },
      {
        value: 'jp',
        label: 'Japan',
      },
    ],
  },
  'react-query-devtool': {
    label: 'Enable react-query devtool',
    type: 'singleSelect',
    options: [
      {
        value: 'bottom-right',
        label: 'Bottom right',
      },
      {
        value: 'top-left',
        label: 'Top left',
      },
      {
        value: 'top-right',
        label: 'Top right',
      },
      {
        value: 'bottom-left',
        label: 'Bottom left',
      },
    ],
  },
  'show-member-id-in-contact-profile': {
    label: 'Show member ID in contact profile',
    type: 'toggle',
  },
  'reveal-segment-id': {
    label: 'Reveal segment ID',
    type: 'toggle',
  },
  'enabled-switch-organization': {
    label: 'Enable Switch Organization',
    type: 'toggle',
  },
});

const releasedFeatureFlags = defineConfig({
  releasedFeatures: {
    type: 'divider',
    label: 'Released Features',
  },
  'auto-segment': { type: 'toggle' },
  'integration-pages': { type: 'toggle' },
  'show-task-key': { label: 'Show task key in TaskProgressModal', type: 'toggle' },
  'show-pushed-tables-card': {
    label:
      'Show auto-reply and tag tables in Insights/Engagement. Show total used point in Insights/Notification Message',
    type: 'toggle',
  },
  'show-journey-v2-scheduled-status': {
    label: 'Show Customer Journey V2 Scheduled Status',
    type: 'toggle',
  },
  'activate-api-client': {
    label: 'Use new typesafe API client for data fetching',
    type: 'toggle',
  },
  'enable-table-view-v2': {
    label: 'Show table view V2 components',
    type: 'toggle',
  },
  vitally: { label: 'Show Vitally NPS', type: 'toggle' },
  'enable-business-hours': {
    label: 'Show business hours',
    type: 'toggle',
  },
  'show-old-auto-reply-module': {
    label: 'Show old Auto-reply module',
    type: 'toggle',
  },
  'show-old-retarget-module': {
    label: 'Show old Retarget module (not including typesafe API client)',
    type: 'toggle',
  },
  'show-old-retarget-message-editor': {
    label: 'Show old Retarget Message Editor (not including new retarget editor module)',
    type: 'toggle',
  },
  'enable-members-api-client': {
    label: 'Enable Members API client',
    type: 'toggle',
  },
  'enable-dpm-new-editor': {
    label: 'Enable DPM new editor',
    type: 'toggle',
  },
  'enable-remember-device': {
    label: 'Enable remember device',
    type: 'toggle',
    autoEnableAt: new Date('2024-11-18T15:00:00+08:00'),
  },
  'enable-authenticator-config': {
    label: 'Enable Authenticator',
    type: 'toggle',
  },
  'enabled-going-cloud': {
    label: 'Enable Going Cloud',
    type: 'toggle',
    autoEnableAt: new Date('2024-11-26T15:00:00+08:00'),
  },
  'enable-api-client-message-schemas': {
    label: 'Enable new message schemas for the API client',
    type: 'toggle',
  },
});

export const featureFlagConfigs = defineConfig({
  ...debugFeatureFlags,
  ...releasedFeatureFlags,
});
