import { colorTokens } from 'init/initMotifColors';

import type { ThemeConfig } from 'antd/es/config-provider';

import { colors } from 'theme/colors';
/**
 * With this theme we aim to follow the System UI theme specification
 *
 * @see https://github.com/system-ui/theme-specification
 */
export const theme = {
  colors,
  radii: {
    buttonStandard: '4px',
    iconStandard: '4px',
  },
  sizes: {
    formFieldWidth: '400px',
    formFieldWidthMedium: '345px',
    mainContentHeaderIcons: '196px',
    mainContentHeaderHeight: '68px',
    mainContentHeaderPadding: '32px',
    mainContentPaddingBottom: '80px',
    mainContentPaddingSide: '32px',
    mainContentPaddingTop: '16px',
    modalWidth: '520px',
    modalWidthMedium: '386px',
    drawerWidth: '857px',
    tooltipLarge: '334px',
    linePreviewHeight: '634px',
    linePreviewFadedHeight: '250px',
    linePreviewWidth: '317px',
    textHeaderLarge: '22px',
    textHeaderMedium: '18px',
    textHeaderSmall: '16px',
    textBodyMedium: '14px',
    textBodySmall: '13px',
  },
  zIndices: {
    drawerDefault: 1000,
    mainContent: 10,
    modalDefault: 100,
    ultimate: 2147483647, // This is the highest possible z-index value for some browsers
  },
  boxShadow: {
    default: '0 1px 6px 0 rgba(9, 23, 42, 0.2)',
  },
};

export const antdTheme: ThemeConfig = {
  token: {
    fontFamily: 'var(--font-family)',
    red: colorTokens.getCssVariableValue('--color-red-60'),
    yellow: theme.colors.yellow006,
    green: theme.colors.green006,
    blue: theme.colors.blue006,
    purple: theme.colors.purple006,
    colorWhite: theme.colors.white001,
    colorPrimary: theme.colors.blue006,
    colorSuccess: theme.colors.green006,
    colorError: colorTokens.getCssVariableValue('--static-fg-negative'),
    colorWarning: theme.colors.yellow006,
    colorInfo: theme.colors.blue004,
    red1: theme.colors.red001,
    red2: theme.colors.red002,
    red3: theme.colors.red003,
    red4: theme.colors.red004,
    red5: theme.colors.red005,
    red6: colorTokens.getCssVariableValue('--color-red-60'),
    red7: theme.colors.red007,
    red8: theme.colors.red008,
    red9: theme.colors.red009,
    red10: theme.colors.red010,
    yellow1: theme.colors.yellow001,
    yellow2: theme.colors.yellow002,
    yellow3: theme.colors.yellow003,
    yellow4: theme.colors.yellow004,
    yellow5: theme.colors.yellow005,
    yellow6: theme.colors.yellow006,
    yellow7: theme.colors.yellow007,
    yellow8: theme.colors.yellow008,
    yellow9: theme.colors.yellow009,
    yellow10: theme.colors.yellow010,
    green1: theme.colors.green001,
    green2: theme.colors.green002,
    green3: theme.colors.green003,
    green4: theme.colors.green004,
    green5: theme.colors.green005,
    green6: theme.colors.green006,
    green7: theme.colors.green007,
    green8: theme.colors.green008,
    green9: theme.colors.green009,
    green10: theme.colors.green010,
    purple1: theme.colors.purple001,
    purple2: theme.colors.purple002,
    purple3: theme.colors.purple003,
    purple4: theme.colors.purple004,
    purple5: theme.colors.purple005,
    purple6: theme.colors.purple006,
    purple7: theme.colors.purple007,
    purple8: theme.colors.purple008,
    purple9: theme.colors.purple009,
    purple10: theme.colors.purple010,
    blue1: theme.colors.blue001,
    blue2: theme.colors.blue002,
    blue3: theme.colors.blue003,
    blue4: theme.colors.blue004,
    blue5: theme.colors.blue005,
    blue6: theme.colors.blue006,
    blue7: theme.colors.blue007,
    blue8: theme.colors.blue008,
    blue9: theme.colors.blue009,
    blue10: theme.colors.blue010,
    borderRadius: parseInt(theme.radii.buttonStandard, 10),
    colorLink: theme.colors.blue006,
  },
  components: {
    Tabs: {
      horizontalItemGutter: 16,
      horizontalItemPadding: '8px',
      horizontalMargin: 'unset',
    },
    Select: {
      optionPadding: '8px 12px',
      optionSelectedFontWeight: 400,
      optionSelectedBg: colorTokens.getCssVariableValue('--interactive-bg-optionSelected'),
    },
  },
};

/**
 * This allows for autocomplete and other things to work as expected
 */
export type ThemeType = typeof theme;
