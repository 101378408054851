import type { DeeplinkMessageMemberValue } from 'modules/Deeplink/types';

/**
 * Sidebar submenu items; these need to be clicked to access actual links
 */
export const SidebarSubmenuIdEnum = {
  Dashboard: 'dashboard',
  Audience: 'audience',
  MessageCenter: 'message-center',
  Applications: 'applications',
  Automation: 'automation',
  Api: 'api',
} as const;

/**
 * Sidebar items; these are the actual links that are accessed by the user and trigger navigation
 */
export const SidebarItemIdEnum = {
  DashboardLineOverview: 'dashboard-line-overview',
  DashboardSmsOverview: 'dashboard-sms-overview',
  DashboardAcquisition: 'dashboard-acquisition',
  DashboardEngagement: 'dashboard-engagement',
  DashboardMaacAi: 'dashboard-maac-ai',
  DashboardConversion: 'dashboard-conversion',
  Members: 'members',
  Segments: 'segments',
  TagManager: 'tag-manager',
  Broadcast: 'broadcast',
  TemplateLibrary: 'template-library',
  RichMenu: 'rich-menu',
  AutoReply: 'auto-reply',
  Deeplink: 'deeplink',
  Widget: 'widget',
  Tracelink: 'tracelink',
  Prize: 'prize',
  Journey: 'journey',
  Retarget: 'retarget',
  Dpm: 'dpm',
  SmsPlus: 'sms-plus',
  Chat: 'chat',
  AppMarketing: 'app-marketing',
  ApiBroadcast: 'api-broadcast',
  ApiToken: 'api-token',
  Zapier: 'zapier',
  Webhook: 'webhook',
} as const;

export type SidebarItemId = (typeof SidebarItemIdEnum)[keyof typeof SidebarItemIdEnum];

export const SidebarIdEnum = {
  ...SidebarSubmenuIdEnum,
  ...SidebarItemIdEnum,
} as const;

export type SidebarId = (typeof SidebarIdEnum)[keyof typeof SidebarIdEnum];

/**
 * The following selection contains all test IDs consumed by Cypress and sprinkled throughout the app
 */
const accountIds = {
  accountSetupRegister: 'setup-register-action',
} as const;

const applicationIds = {
  mainContent: (id: SidebarItemId) => `main-content-${id}`,
  sidebar: (id: SidebarId) => `sidebar-${id}`,
} as const;

const appMarketingIds = {
  appMarketingRankings: 'app-marketing-rankings',
} as const;

const autoReplyIds = {
  autoReplyListCreateButton: 'autoreply-create-button',
  autoReplyListArchivedTab: 'autoreply-tabs-archived',
  autoReplyListKeywordTab: 'autoreply-tabs-keyword',
  autoReplyFormFieldName: 'autoreply-name-field',
  autoReplyFormFieldEventTypeSelect: 'autoreply-event-type-select',
  autoReplyFormFieldKeyword: 'autoreply-keyword-field',
  autoReplyFormFieldKeywordScheduleSelect: 'autoreply-keyword-schedule-select',
  autoReplyFormFieldFixedReplyScheduleSelect: 'autoreply-fixed-reply-schedule-select',
  autoReplyFormButtonNext: 'autoreply-create-action-next',
} as const;

const broadcastIds = {
  broadcastFormActionNext: 'broadcast-action-next',
  broadcastFormFieldName: 'broadcast-field-name',
  broadcastFormSaveDraft: 'broadcast-save-as-draft',
  broadcastList: 'broadcast-list',
  broadcastListCreateButton: 'broadcast-create-button',
  broadcastListMenuDelete: 'broadcast-menu-delete',
  broadcastListDeleteModalConfirm: 'broadcast-list-delete-confirm-button',
} as const;

const deeplinkIds = {
  deeplinkEditLink: (id: number) => `deeplink-edit-link-${id}`,
  deeplinkCopyLink: (id: number) => `deeplink-copy-link-${id}`,
  deeplinkArchiveLink: (id: number) => `deeplink-archive-link-${id}`,
  deeplinkMenuButton: (id: number) => `deeplink-menu-button-${id}`,
  deeplinkBatchActionEdit: 'deeplink-batch-action-edit',
  deeplinkBatchActionCreate: 'deeplink-batch-action-create',
  deeplinkBatchSelectExport: 'deeplink-batch-select-export',
  deeplinkListCreateButton: 'deeplink-create-button',
  deeplinkCreateConfirmButton: 'deeplink-create-confirm-save',
  deeplinkCreateNextButton: 'deeplink-create-action-next',
  deeplinkCreateMemberToggle: (id: DeeplinkMessageMemberValue) =>
    `deeplink-create-member-message-toggle-${id}`,
  deeplinkCreateNameField: 'deeplink-name-field',
} as const;

const editorIds = {
  editorModuleSelectorTextModule: 'module-selector-text-module',
  editorRichText: 'rich-text-editor',
  editorTemplateDropdown: 'template-dropdown',
  editorTemplateDropdownSave: 'template-dropdown-save-as-template',
  editorTemplateSaveModalNameInput: 'template-save-as-modal-name-input',
  editorTemplateSaveModalSubmit: 'template-save-as-modal-submit',
} as const;

const retargetIds = {
  retargetList: 'retarget-list',
  retargetListCreateButton: 'retarget-list-create-button',
  retargetCreateNextButton: 'retarget-create-next-button',
  retargetCreateNameField: 'retarget-create-name-field',
  retargetCreateSaveDraftButton: 'retarget-create-save-draft-button',
  retargetListEditButton: (id: number) => `retarget-list-edit-button-${id}`,
  retargetListDeleteButton: (id: number) => `retarget-list-delete-button-${id}`,
  retargetModalDeleteButton: 'retarget-modal-delete-button',
} as const;

const tagManagerIds = {
  tagManagerList: 'tag-manager-list',
} as const;

/**
 * All test IDs consumed by Cypress
 */
export const testIds = {
  ...accountIds,
  ...applicationIds,
  ...appMarketingIds,
  ...autoReplyIds,
  ...broadcastIds,
  ...deeplinkIds,
  ...editorIds,
  ...retargetIds,
  ...tagManagerIds,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} satisfies Record<string, string | ((arg: any) => string)>;

export type TestIds = typeof testIds;
