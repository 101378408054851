import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import { AuthAxios } from 'lib/axios';
import { accountApi } from 'shared/api/account';
import { autoReplyApi } from 'shared/api/autoReply';
import { broadcastSmsApi } from 'shared/api/broadcastSms';
import { cdpApi } from 'shared/api/cdp';
import { deeplinkBatchApi } from 'shared/api/deeplinkBatch';
import { deeplinkOldApi } from 'shared/api/deeplinkOld';
import { integrationsApi } from 'shared/api/integrations';
import { memberApi } from 'shared/api/member';
import { npsApi } from 'shared/api/nps';
import { organizationApi } from 'shared/api/organization';
import { receiptRegisterApi } from 'shared/api/receiptRegister';
import { retargetApi } from 'shared/api/retarget';
import { validationPlugin } from 'shared/lib/zodios/validationPlugin';

const apiClient = new Zodios(
  [
    ...accountApi,
    ...autoReplyApi,
    ...broadcastSmsApi,
    ...cdpApi,
    ...deeplinkOldApi,
    ...deeplinkBatchApi,
    ...integrationsApi,
    ...memberApi,
    ...organizationApi,
    ...receiptRegisterApi,
    ...npsApi,
    ...retargetApi,
  ],
  {
    axiosInstance: AuthAxios,
  },
);

apiClient.use(validationPlugin);

const apiHooks = new ZodiosHooks('maac', apiClient);

export { apiClient, apiHooks };
