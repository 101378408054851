import { makeApi } from '@zodios/core';
import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { ExportResponseSchema, ListResponseSchema } from 'shared/models/common';
import { DeeplinkReportSchema } from 'shared/models/deeplink/common';
import { MessageApiSchema } from 'shared/models/editor/api';
import { EditorMessagePayloadSchema } from 'shared/models/editor/message';
import { TagMetadataItemWithOrgIdSchema } from 'shared/models/tag/metadata';

// Deeplink message payload; a limited subset of the standard Message type
const DeeplinkEditorMessagePayloadSchema = EditorMessagePayloadSchema.pick({
  messages: true,
  enable: true,
});

// Note: the types may be very different as Deeplink currently uses only a very basic message schema
const DeeplinkEditorMessagePayloadSchemaV2 = z.object({
  messages: MessageApiSchema.array(),
  enable: z.boolean(),
});

const DeeplinkDetailSchema = z.object({
  id: z.number().int(),
  bot_id: z.number().int(),
  name: z.string(),
  created_at: z.string(),
  shorten_url: z.string(),
  description: z.string().nullable(),
  repeatable: z.boolean(),
  new_friend_messages: DeeplinkEditorMessagePayloadSchema,
  existing_friend_messages: DeeplinkEditorMessagePayloadSchema,
  tags: TagMetadataItemWithOrgIdSchema.array(),
});

const DeeplinkMetadataItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  shorten_url: z.string(),
});

const DeeplinkListItemSchema = z.object({
  id: z.number().int(),
  name: z.string(),
  created_at: z.string(),
  shorten_url: z.string(),
  report: DeeplinkReportSchema,
});

const DeeplinkCreateParameters = z.object({
  bot_id: z.number().int(),
  name: z.string(),
  description: z.string().nullable().optional(),
  repeatable: z.boolean().optional(),
  new_friend_messages: DeeplinkEditorMessagePayloadSchema,
  existing_friend_messages: DeeplinkEditorMessagePayloadSchema,
  tags: z.number().int().array().optional(),
});

export const deeplinkOldApi = makeApi([
  {
    alias: 'deeplinkList',
    method: 'get',
    path: '/line/v2/deeplink/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'archived',
        schema: z.boolean().optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'cursor',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: ListResponseSchema.extend({
      results: DeeplinkListItemSchema.strict().array(),
    }),
  },
  {
    alias: 'deeplinkListMetrics',
    method: 'get',
    path: '/line/v2/deeplink/metric/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        active: z.number().int(),
        archived: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'deeplinkListSearchResultCount',
    method: 'get',
    path: '/line/v2/deeplink/count/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'archived',
        schema: z.boolean().optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        count: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'deeplinkDetail',
    method: 'get',
    path: '/line/v2/deeplink/:deeplinkId/',
    response: DeeplinkDetailSchema.strict(),
  },
  {
    alias: 'deeplinkReport',
    method: 'get',
    path: '/line/v2/deeplink/:deeplinkId/report/',
    parameters: [
      {
        name: 'start_date',
        schema: z.string(),
        type: 'Query',
      },
      {
        name: 'end_date',
        schema: z.string(),
        type: 'Query',
      },
    ],
    response: DeeplinkReportSchema.strict(),
  },
  {
    alias: 'deeplinkMetadata',
    method: 'get',
    path: '/line/v2/deeplink/raw_deeplinks/',
    parameters: [
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'archived',
        schema: z.boolean(),
        type: 'Query',
      },
    ],
    response: DeeplinkMetadataItemSchema.strict().array(),
  },
  {
    alias: 'deeplinkCreate',
    method: 'post',
    path: '/line/v2/deeplink/',
    parameters: [
      {
        name: 'body',
        schema: DeeplinkCreateParameters.strict(),
        type: 'Body',
      },
    ],
    response: DeeplinkDetailSchema.strict(),
  },
  {
    alias: 'deeplinkUpdate',
    method: 'patch',
    path: '/line/v2/deeplink/:deeplinkId/',
    parameters: [
      {
        name: 'body',
        schema: DeeplinkCreateParameters.partial()
          .extend({
            id: z.number().int(),
            archived: z.boolean().optional(),
          })
          .strict(),
        type: 'Body',
      },
    ],
    response: DeeplinkDetailSchema.strict(),
  },
  {
    alias: 'deeplinkReportExport',
    method: 'post',
    path: '/line/v2/deeplink/export/',
    parameters: [
      {
        name: 'body',
        schema: z
          .object({
            bot_id: z.number().int(),
            start_date: z.string(),
            end_date: z.string(),
          })
          .strict(),
        type: 'Body',
      },
      {
        name: 'bot_id',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'archived',
        schema: z.boolean().optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
    ],
    response: ExportResponseSchema,
  },
]);

export type DeeplinkEditorMessagePayload = z.infer<typeof DeeplinkEditorMessagePayloadSchema>;

export type DeeplinkEditorMessagePayloadV2 = z.infer<typeof DeeplinkEditorMessagePayloadSchemaV2>;

export type DeeplinkListItem = z.output<typeof DeeplinkListItemSchema>;

export type DeeplinkListResponse = ZodiosResponseByAlias<typeof deeplinkOldApi, 'deeplinkList'>;

export type DeeplinkListMetricsResponse = ZodiosResponseByAlias<
  typeof deeplinkOldApi,
  'deeplinkListMetrics'
>;

export type DeeplinkReportDetail = z.output<typeof DeeplinkReportSchema>;
