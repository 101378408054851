import { Zodios } from '@zodios/core';
import { ZodiosHooks } from '@zodios/react';

import type { ZodiosEndpointDefinitions } from '@zodios/core';
import type { Narrow } from '@zodios/core/lib/utils.types';

import { AuthAxios } from 'lib/axios';
import { getValidationPlugin } from 'shared/lib/zodios/validationPlugin';

// This custom wrapper allows us to define smaller API clients on a per-feature or per-module basis
export function makeApi<Api extends ZodiosEndpointDefinitions>(
  id: string,
  apiDefinition: Narrow<Api>,
) {
  const apiName = `maac-api-${id}`;

  const apiClient = new Zodios(apiDefinition, {
    axiosInstance: AuthAxios,
  });

  apiClient.use(getValidationPlugin(apiName));

  const apiHooks = new ZodiosHooks(apiName, apiClient);

  return { apiDefinition, apiClient, apiHooks };
}
