import { inspectMessage } from '@chatbotgang/etude/debug/inspectMessage';
import { boolean } from '@chatbotgang/etude/pitch-shifter/boolean';
import { object } from '@chatbotgang/etude/pitch-shifter/object';
import { assignDisplayName } from '@chatbotgang/etude/react/assignDisplayName';
import { forwardRef } from '@chatbotgang/etude/react/forwardRef';
import mapValues from 'lodash/mapValues';
import { Fragment, useCallback, useMemo } from 'react';
import { isDeepEqual, pick } from 'remeda';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

import {
  APP_LOADED_DATE,
  GLOBAL_SEARCH_PARAM_FEATURE_FLAG,
  LOCAL_STORAGE_FEATURE_FLAG,
} from 'AppConstants';

import type {
  BaseFeatureFlagConfigs,
  BaseSingleSelectFeatureFlagConfig,
  BaseToggleFeatureFlagConfig,
} from './baseTypes';
import type { ComponentProps, ComponentType, ElementRef, ReactNode } from 'react';
import type { ConditionalKeys } from 'type-fest';

import { createZustandStorageStore } from 'utils/createZustandStorageStore';
import { parseJson } from 'utils/parseJson';

type Values<FeatureFlagConfigs extends BaseFeatureFlagConfigs> = {
  [K in keyof FeatureFlagConfigs]: FeatureFlagConfigs[K] extends BaseToggleFeatureFlagConfig
    ? boolean
    : FeatureFlagConfigs[K] extends BaseSingleSelectFeatureFlagConfig
      ? FeatureFlagConfigs[K]['options'][number]['value'] | null
      : never;
};

interface Types<FeatureFlagConfigs extends BaseFeatureFlagConfigs> {
  /**
   * All feature flag keys.
   */
  Key: keyof FeatureFlagConfigs & string;
  FunctionalKey:
    | Types<FeatureFlagConfigs>['ToggleKey']
    | Types<FeatureFlagConfigs>['SingleSelectKey'];
  /**
   * Get all toggle feature flag keys.
   */
  ToggleKey: ConditionalKeys<
    FeatureFlagConfigs,
    {
      type: 'toggle';
    }
  >;
  /**
   * Get all single select feature flag keys.
   */
  SingleSelectKey: ConditionalKeys<
    FeatureFlagConfigs,
    {
      type: 'singleSelect';
    }
  >;
  /**
   * Get all feature flag values.
   */
  Values: Values<FeatureFlagConfigs>;
}

function createFeatureFlagApi<FeatureFlagConfigs extends BaseFeatureFlagConfigs>(
  configs: FeatureFlagConfigs,
) {
  type LocalTypes = Types<typeof configs>;

  function isToggleFeatureFlagKey<K extends LocalTypes['Key']>(
    key: K,
  ): key is K & LocalTypes['ToggleKey'] {
    return configs[key].type === 'toggle';
  }

  function isSingleSelectFeatureFlagKey<K extends LocalTypes['Key']>(
    key: K,
  ): key is K & LocalTypes['SingleSelectKey'] {
    return configs[key].type === 'singleSelect';
  }

  const functionalFeatureFlagKeys = Object.keys(configs).filter(
    (key) =>
      isToggleFeatureFlagKey(key as LocalTypes['Key']) ||
      isSingleSelectFeatureFlagKey(key as LocalTypes['Key']),
  ) as Array<LocalTypes['FunctionalKey']>;

  const functionalFeatureFlags = pick(configs, functionalFeatureFlagKeys);

  const parser: (v: unknown) => LocalTypes['Values'] = object(
    mapValues(functionalFeatureFlags, (featureFlag) => {
      if (!featureFlag) {
        throw new Error('Feature flags undefined!');
      }

      switch (featureFlag.type) {
        case 'toggle':
          return (v: unknown) =>
            featureFlag.autoEnableAt !== undefined && APP_LOADED_DATE >= featureFlag.autoEnableAt
              ? false
              : boolean()(v);
        case 'singleSelect':
          return (v: unknown) =>
            featureFlag.options
              .map(({ value }) => value)
              .includes(
                // eslint-disable-next-line @typescript-eslint/no-explicit-any
                v as any, // We don't care about the type of v here.
              )
              ? v
              : null;
        default:
          // Prevent unhandled cases.
          featureFlag.type satisfies 'divider';
          throw new Error(inspectMessage`Unhandled feature flag type: ${featureFlag}`);
      }
    }) as {
      [K in LocalTypes['Key']]: (v: unknown) => LocalTypes['Values'][K];
    },
  ) as (v: unknown) => LocalTypes['Values'];

  function useFeatureFlag<K extends LocalTypes['Key']>(key: K): LocalTypes['Values'][K] {
    const value = useFeatureFlagStore((state) => state.value[key]);
    return value;
  }

  function useGetFeatureFlag(): <K extends LocalTypes['Key']>(key: K) => LocalTypes['Values'][K] {
    const value = useFeatureFlagStore((state) => state.value);
    return useCallback((feature) => value[feature], [value]);
  }

  const { useStore: useFeatureFlagLocalStorageStore } = createZustandStorageStore<
    LocalTypes['Values']
  >(LOCAL_STORAGE_FEATURE_FLAG, parser, {
    /**
     * We don't always want to clear the feature flag when the user changes.
     */
    forUser: false,
  });

  /**
   * Transform the value from the local storage store to the feature flag store.
   */
  function getComputedValue(value: LocalTypes['Values']): LocalTypes['Values'] {
    return mapValues(value, (v, key) => {
      const config = configs[key];
      if (config?.type === 'toggle') {
        // If the feature is auto-enabled, return true.
        if (config.autoEnableAt !== undefined && APP_LOADED_DATE >= config.autoEnableAt)
          return true;
        return v;
      }
      return v;
    }) as LocalTypes['Values'];
  }

  const useFeatureFlagStore = createWithEqualityFn<{
    value: LocalTypes['Values'];
  }>()(
    () => ({
      value: getComputedValue(useFeatureFlagLocalStorageStore.getState().value),
    }),
    shallow,
  );

  useFeatureFlagLocalStorageStore.subscribe(({ value }) => {
    const computedValue = getComputedValue(value);
    const currentValue = useFeatureFlagStore.getState().value;
    if (isDeepEqual(currentValue, computedValue)) return;
    useFeatureFlagStore.setState({ value: computedValue });
  });

  /**
   * Check if any feature flags are enabled. Utilize stored data instead of
   * computed data here, as auto-enabled flags are true in computed data and
   * consistently false in stored data.
   */
  function useEnabledSomeFeatureFlags(): boolean {
    return useFeatureFlagLocalStorageStore((state) =>
      Object.entries(functionalFeatureFlags).some(([key]) => {
        const typedKey = key as string & keyof typeof functionalFeatureFlags;
        const typedFeature = functionalFeatureFlags[typedKey];
        switch (typedFeature.type) {
          case 'toggle':
            return state.value[typedKey];
          case 'singleSelect':
            return state.value[typedKey] !== null;
          default:
            typedFeature.type satisfies 'divider';
            return false;
        }
      }),
    );
  }

  /**
   * A simple wrapper component to toggle showing children
   * @example
   * ```tsx
   * <FeatureFlag
   *    feature='your-feature'
   * >
   *  /// your feature component
   * </FeatureFlag>
   * ```
   */
  function FeatureFlag({
    feature: featureKey,
    children,
  }: {
    feature: LocalTypes['Key'];
    children: ReactNode;
  }): JSX.Element | null {
    const enabled = useFeatureFlag(featureKey);
    return useMemo<JSX.Element | null>(() => <>{enabled ? children : null}</>, [children, enabled]);
  }

  /**
   * This is a HOC that wraps a component with a feature flag check. If the
   * feature flag is enabled, the component will be rendered with the provider.
   * Otherwise, the component will be rendered with Fragment.
   *
   * @example
   * ```ts
   * import { withFeatureFlagWrapper } from '@/shared/components/FeatureFlag/hoc';
   *
   * const ComponentWithFeatureFlagWrapper = withFeatureFlagWrapper('myFeature', MyComponent, FallbackComponent);
   * ```
   *
   * It's equal to:
   *
   * ```tsx
   * function ComponentWithFeatureFlagWrapper({children}: {children: ReactNode}) {
   *   const enabled = useFeatureFlag('myFeature');
   *   return enabled ? <MyComponent>{children}</MyComponent> : <FallbackComponent>{children}</FallbackComponent>;
   * }
   * ```
   *
   * `FallbackComponent` is optional, if not provided, it will be rendered with
   * `Fragment`.
   */
  function withFeatureFlagWrapper<
    Element extends ComponentType<{
      children: ReactNode;
    }>,
  >(feature: LocalTypes['Key'], Component: Element, FallbackComponent = Fragment) {
    const wrappedDisplayName = `withFeatureFlagWrapper(${feature})`;
    const WithFeatureFlag = forwardRef<ElementRef<Element>, ComponentProps<Element>>(
      assignDisplayName(function WithFeatureFlag(props, ref) {
        const enabled = useFeatureFlag(feature);
        const Wrapper = enabled ? Component : FallbackComponent;
        return <Wrapper {...props} />;
      }, wrappedDisplayName),
    );
    assignDisplayName(WithFeatureFlag, wrappedDisplayName);

    return WithFeatureFlag;
  }

  (function loadFeatureFlagFromUrl() {
    const featureFlag = new URLSearchParams(window.location.search).get(
      GLOBAL_SEARCH_PARAM_FEATURE_FLAG,
    );
    if (featureFlag === '') {
      useFeatureFlagLocalStorageStore.getState().clear();
    } else if (featureFlag !== null) {
      const featureFlagObject = parser(parseJson(featureFlag, { fallback: null }));
      useFeatureFlagLocalStorageStore.getState().setValue(featureFlagObject);
    }
    const draftLocation = new URL(window.location.href);
    const params = new URLSearchParams(draftLocation.search);
    params.delete(GLOBAL_SEARCH_PARAM_FEATURE_FLAG);
    draftLocation.search = params.toString();
    window.history.replaceState({}, '', draftLocation.toString());
  })();

  const ret = {
    configs,
    isToggleFeatureFlagKey,
    isSingleSelectFeatureFlagKey,
    useEnabledSomeFeatureFlags,
    useFeatureFlag,
    useFeatureFlagLocalStorageStore,
    useFeatureFlagStore,
    useGetFeatureFlag,
    FeatureFlag,
    withFeatureFlagWrapper,
  };

  return ret;
}

// eslint-disable-next-line react-refresh/only-export-components
export { createFeatureFlagApi };

export type { Types };
