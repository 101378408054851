import { NEVER, z } from 'zod';

import {
  MAX_BUTTON_LABEL_LENGTH,
  MAX_TEXT_MODULE_TEXT_LENGTH,
  MESSAGE_MODULE_TYPE,
  PARAMETER_DATA_FUNCTION,
  PARAMETER_TYPE,
} from 'shared/components/Editor/LineMessageEditor/constants';

import type {
  DEFAULT_MAPPING_KEY,
  TEXT_MODULE_TAG_TYPE,
} from 'shared/components/Editor/LineMessageEditor/constants';

import { isUrl } from 'lib/validator/rules';
import { i18n } from 'modules/G11n/i18n';
import {
  FlexBoxCustomSchema,
  FlexBoxJustifyContentSchema,
  FlexBoxSpacingSchema,
  FlexImageSchema,
  FlexTextMarginSchema,
  FlexTextSizeSchema,
  FlexTextWeightSchema,
} from 'shared/models/editor/lineSdk';

export type DefaultMappingKey = keyof typeof DEFAULT_MAPPING_KEY;
export type TextModuleTagType = (typeof TEXT_MODULE_TAG_TYPE)[keyof typeof TEXT_MODULE_TAG_TYPE];
export type MessageModuleType = (typeof MESSAGE_MODULE_TYPE)[keyof typeof MESSAGE_MODULE_TYPE];

// TODO: this file contains many uses of `z.custom()`, which is effectively a type assertion
// We should at some point integrate with schemas in the LINE SDK model

const isInvalidApiParameter = (value: string) => {
  if (value === 'url' || value === 'name') {
    return true;
  }
  return !/^[a-z\d\-_]+$/i.test(value);
};

const createStringSchema = ({
  required = false,
  max,
  pattern,
}: {
  required?: boolean;
  max?: number;
  pattern?: {
    isUrl?: boolean;
    isApiParameter?: boolean;
  };
}) => {
  return z.string().superRefine((value, ctx) => {
    const trimmedValue = value.trim();
    if (required && trimmedValue.length <= 0) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t('common.messageCanNotBeEmpty'),
      });
      return z.NEVER;
    }
    if (max && trimmedValue.length > max) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t('validation.maxCharLength', { count: max }),
      });
      return z.NEVER;
    }
    if (pattern?.isUrl && !isUrl(trimmedValue)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t('validation.pleaseInputValidUrl'),
      });
      return z.NEVER;
    }

    if (pattern?.isApiParameter && isInvalidApiParameter(trimmedValue)) {
      ctx.addIssue({
        code: z.ZodIssueCode.custom,
        message: i18n.t('validation.apiParameter.inValid'),
      });
    }
  });
};

const CustomParameterSchema = z.object({
  type: z.literal(PARAMETER_TYPE.custom_string),
  key: createStringSchema({ required: true, pattern: { isApiParameter: true } }),
  mappingKey: z.string(),
  data: z.object({ function: z.literal(null) }),
});

const CustomParameterWithLinkSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.link),
  open_external_browser: z.boolean(),
  url: createStringSchema({ required: true, pattern: { isUrl: true } }),
  isUtmTracked: z.boolean().default(false).optional(),
  utm_source: z.string().optional(),
  utm_medium: z.string().optional(),
  utm_campaign: z.string().optional(),
  utm_content: z.string().optional(),
  tag_list: z.array(z.string()),
  messagelink_id: z.number().int().optional(),
});

export type CustomParameterWithLink = z.infer<typeof CustomParameterWithLinkSchema>;

const StandardParameteDataWithTagSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.tag),
});

const StandardParameterDataWithMemberNameSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.memberName),
});

const StandardParameterWithDataReferralInviterSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.referralInviter),
});

const StandardParameterWithDataReferralInviteeSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.referralInvitee),
});

const StandardParameterWithReferrDataalActivityLinkSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.referralActivity_link),
});

const StandardParameterWithReferralDataInvitationLinkSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.referralInvitation_link),
});

const StandardParameterDataWithNullSchema = z.object({
  function: z.literal(null),
});

const StandardParameterDataWithSharelinkSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.sharelink),
  tag_list: z.array(z.string()),
  name: createStringSchema({ required: true, max: 40 }),
});

export type StandardParameterDataWithSharelink = z.infer<
  typeof StandardParameterDataWithSharelinkSchema
>;

const StandardParameterDataWithPrizeNameSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.prizeName),
  prize_setting_id: z.number().int().positive(),
});

const StandardParameterDataWithPrizeLiffPageSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.prizeLiffPage),
  prize_setting_id: z.number().int().positive(),
});

const StandardParameterWithBindlinkSchema = z.object({
  function: z.literal(PARAMETER_DATA_FUNCTION.bindLink),
  bindlink_id: z.number().int(),
});

const ParameterDataSchema = z.discriminatedUnion('function', [
  CustomParameterWithLinkSchema,
  StandardParameteDataWithTagSchema,
  StandardParameterDataWithMemberNameSchema,
  StandardParameterWithDataReferralInviterSchema,
  StandardParameterWithDataReferralInviteeSchema,
  StandardParameterWithReferrDataalActivityLinkSchema,
  StandardParameterWithReferralDataInvitationLinkSchema,
  StandardParameterDataWithNullSchema,
  StandardParameterDataWithSharelinkSchema,
  StandardParameterDataWithPrizeNameSchema,
  StandardParameterDataWithPrizeLiffPageSchema,
  StandardParameterWithBindlinkSchema,
]);

export type ParameterData = z.infer<typeof ParameterDataSchema>;

const StandardParameterSchema = z.object({
  type: z.literal(PARAMETER_TYPE.standard),
  key: z.string(),
  isShowPopup: z.boolean().default(false).optional(),
  data: ParameterDataSchema.superRefine((data, ctx) => {
    if (data.function === 'link' && data.isUtmTracked) {
      const utmValidator = (value: string | undefined, path: string) => {
        const trimmedValue = value?.trim() ?? '';
        if (trimmedValue?.length <= 0) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t('common.messageCanNotBeEmpty'),
            path: [path],
          });
          return NEVER;
        }
        if (trimmedValue.length > 128) {
          ctx.addIssue({
            code: z.ZodIssueCode.custom,
            message: i18n.t('validation.maxCharLength', { count: 128 }),
            path: [path],
          });
        }
      };

      utmValidator(data.utm_source, 'utm_source');
      utmValidator(data.utm_medium, 'utm_medium');
      utmValidator(data.utm_campaign, 'utm_campaign');
    }
  }),
});

export type CustomParameter = z.infer<typeof CustomParameterSchema>;
export type StandardParameter = z.infer<typeof StandardParameterSchema>;

const ParameterSchema = z.discriminatedUnion('type', [
  StandardParameterSchema,
  CustomParameterSchema,
]);

export type Parameter = z.infer<typeof ParameterSchema>;

const QuickReplySchema = z.object({
  items: z.array(
    z.object({
      type: z.literal('action'),
      imageUrl: z.string().optional(),
      action: z.discriminatedUnion('type', [
        z.object({
          type: z.literal('message'),
          text: createStringSchema({ required: true, max: 20 }),
          label: createStringSchema({ required: true, max: 20 }),
        }),
        z.object({
          type: z.enum(['camera', 'cameraRoll', 'location']),
          label: createStringSchema({ required: true, max: 20 }),
        }),
      ]),
    }),
  ),
});

const SmsModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.sms),
  data: z.object({
    text: z.string(),
  }),
  parameters: z.array(ParameterSchema).default([]),
  quick_reply: QuickReplySchema.optional(),
});

const FlexModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.flex),
  data: z.object({
    notification_text: createStringSchema({ required: true, max: 300 }),
    contents: z.object({
      body: z.object({
        type: z.literal('box'),
        layout: z.literal('vertical'),
        contents: FlexBoxCustomSchema.array(),
      }),
      size: z.literal('giga').optional(),
      type: z.literal('bubble'),
      header: z.object({
        type: z.literal('box'),
        layout: z.literal('vertical'),
        contents: FlexBoxCustomSchema.array(),
      }),
    }),
  }),
  parameters: z.array(ParameterSchema).default([]),
  quick_reply: QuickReplySchema.optional(),
});

const TextModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.text),
  data: z.object({
    text: createStringSchema({ required: true, max: MAX_TEXT_MODULE_TEXT_LENGTH }),
  }),
  actions: z.array(z.object({})).optional(),
  parameters: z.array(ParameterSchema).default([]),
  quick_reply: QuickReplySchema.optional(),
});

const ImageModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.image),
  data: z.object({
    key: z.string().optional(),
    content_url: z.string().superRefine((value, ctx) => {
      if (value.length <= 0) {
        ctx.addIssue({
          code: z.ZodIssueCode.custom,
          message: i18n.t('message.paramImageEditor.pleaseUploadImage'),
        });
        return z.NEVER;
      }
    }),
    actions: z.array(z.object({})).optional(),
    parameters: z.array(z.object({})).optional(),
  }),
  actions: z.array(z.object({})).optional(),
  parameters: z.array(CustomParameterSchema),
  quick_reply: QuickReplySchema.optional(),
});

const MessageActionSchema = z.object({
  type: z.literal('message'),
  label: z.literal('action').optional(),
  text: createStringSchema({ required: true, max: 300 }),
});

const UriActionSchema = z.object({
  type: z.enum(['uri', 'customUri']),
  uri: z.string(),
  /** key is for the mapping key of the custom uri */
  key: z.string().optional(),
});

const LineMessageActionSchema = z.discriminatedUnion('type', [
  MessageActionSchema,
  UriActionSchema,
]);
export type LineMessageAction = z.infer<typeof LineMessageActionSchema>;
export type MessageAction = z.infer<typeof MessageActionSchema>;
export type UriAction = z.infer<typeof UriActionSchema>;

export type ImageCarouselContentHeroAction = z.infer<typeof LineMessageActionSchema>;

const ImageCarouselContentSchema = z.object({
  type: z.literal('bubble'),
  direction: z.literal('ltr'),
  hero: z.object({
    type: z.literal('box'),
    layout: z.literal('vertical'),
    action: LineMessageActionSchema,
    contents: z.array(
      z.object({
        key: z.string().optional(),
        url: z.string().superRefine((value, ctx) => {
          if (value.length <= 0) {
            ctx.addIssue({
              code: z.ZodIssueCode.custom,
              message: i18n.t('message.paramImageEditor.pleaseUploadImage'),
            });
            return z.NEVER;
          }
        }),
        size: z.literal('full').optional(),
        type: z.literal('image'),
        animated: z.boolean().optional(),
        aspectMode: z.enum(['cover', 'fit']).optional(),
        aspectRatio: z.string().optional(),
      }),
    ),
  }),
});

export type ImageCarouselContent = z.infer<typeof ImageCarouselContentSchema>;

const ImageCarouselModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.imageCarousel),
  data: z.object({
    notification_text: createStringSchema({ required: true, max: 300 }),
    contents: z.array(ImageCarouselContentSchema),
  }),
  parameters: z.array(ParameterSchema),
  quick_reply: QuickReplySchema.optional(),
});

const VideoModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.video),
  data: z.object({
    notification_text: createStringSchema({ required: true, max: 300 }),
    actions: z.array(z.object({})).optional(),
    base_url: z.string(),
    base_size: z.object({ width: z.number(), height: z.number() }),
    video: z.object({
      area: z.object({
        x: z.number(),
        y: z.number(),
        width: z.number(),
        height: z.number(),
      }),
      expired: z.boolean(),
      metadata: z.object({ width: z.number(), height: z.number() }),
      externalLink: z.object({
        label: createStringSchema({ required: true, max: 20 }),
        linkUri: z.string(),
      }),
      previewImageUrl: z.string(),
      originalContentUrl: z.string(),
    }),
  }),
  parameters: z.array(ParameterSchema),
  quick_reply: QuickReplySchema.optional(),
});

const ImagemapActionSchema = z.discriminatedUnion('type', [
  z.object({
    type: z.literal('message'),
    key: z.string().optional(),
    text: createStringSchema({ required: true, max: 300 }),
    area: z.object({
      x: z.number(),
      y: z.number(),
      width: z.number(),
      height: z.number(),
    }),
  }),
  z.object({
    type: z.enum(['uri', 'customUri']),
    linkUri: z.string(),
    area: z.object({
      x: z.number(),
      y: z.number(),
      width: z.number(),
      height: z.number(),
    }),
  }),
]);

export type ImagemapAction = z.infer<typeof ImagemapActionSchema>;

const ImagemapTriggerSchema = z.object({
  key: z.string(),
  trigger_type: z.string(),
  trigger_code: z.string(),
  data: z.object({
    type: z.literal('standard'),
    function: z.literal('tag'),
    tag_list: z.array(z.string()),
  }),
});

export type ImagemapTrigger = z.infer<typeof ImagemapTriggerSchema>;

const ImagemapContentImageSchema = z.object({
  type: z.literal('image'),
  size: z.literal('full').optional(),
  url: z.string(),
  gravity: z.enum(['top', 'bottom', 'center']).optional(),
  animated: z.boolean().optional(),
  aspectMode: z.enum(['cover', 'fit']).optional(),
  aspectRatio: z.string().optional(),
});

export type ImagemapContentImage = z.infer<typeof ImagemapContentImageSchema>;

const ImagemapContentBoxSchema = z.object({
  type: z.literal('box'),
  width: z.string(),
  height: z.string().optional(),
  layout: z.literal('vertical'),
  contents: z.array(z.never()),
  position: z.enum(['absolute']),
  offsetTop: z.string().optional(),
  offsetStart: z.string(),
  action: z.discriminatedUnion('type', [MessageActionSchema, UriActionSchema]),
});

export type ImagemapContentBox = z.infer<typeof ImagemapContentBoxSchema>;

const ImagemapContentSchema = z.discriminatedUnion('type', [
  ImagemapContentImageSchema,
  ImagemapContentBoxSchema,
]);

export type ImagemapContent = z.infer<typeof ImagemapContentSchema>;

const ImagemapContentsSchema = z.object({
  body: z.object({
    type: z.literal('box'),
    layout: z.literal('vertical'),
    paddingAll: z.string(),
    contents: z.tuple([ImagemapContentImageSchema]).rest(ImagemapContentBoxSchema),
  }),
  size: z.enum(['giga']),
  type: z.literal('bubble'),
});

export type ImagemapContents = z.infer<typeof ImagemapContentsSchema>;

const ImagemapModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.imagemap),
  data: z.object({
    notification_text: createStringSchema({ required: true, max: 300 }),
    contents: ImagemapContentsSchema.optional(),
    animated: z.boolean().optional(),
    base_url: z.string(),
    base_size: z.object({
      width: z.number(),
      height: z.number(),
    }),
    actions: z.array(ImagemapActionSchema),
  }),
  actions: z.array(ImagemapTriggerSchema),
  parameters: z.array(ParameterSchema),
  quick_reply: QuickReplySchema.optional(),
  format: z.object({
    tagId: z.number(),
    isFlex: z.boolean().optional(),
    isCustomBlock: z.boolean(),
  }),
  isOpen: z.boolean(),
  isCustom: z.boolean(),
  activeBlockIndex: z.number(),
});

const ImagemapFlexModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.imagemapFlex),
  data: z.object({
    base_url: z.string().optional(),
    animated: z.boolean().optional(),
    base_size: z
      .object({
        width: z.number(),
        height: z.number(),
      })
      .optional(),
    notification_text: createStringSchema({ required: true, max: 300 }),
    contents: ImagemapContentsSchema.optional(),
    actions: z.array(ImagemapActionSchema).optional(),
  }),
  actions: z.array(ImagemapTriggerSchema),
  parameters: z.array(ParameterSchema),
  quick_reply: QuickReplySchema.optional(),
  /** UI related field */
  activeBlockIndex: z.number(),
  isOpen: z.boolean(),
  isCustom: z.boolean(),
  format: z.object({
    tagId: z.number(),
    isFlex: z.boolean().optional(),
    isCustomBlock: z.boolean(),
  }),
});
const ImagemapCarouselModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.imagemapCarousel),
  data: z.object({
    notification_text: createStringSchema({ required: true, max: 300 }),
    contents: z.object({
      type: z.literal('carousel'),
      contents: z.array(ImagemapContentsSchema),
    }),
  }),
  actions: z.array(ImagemapTriggerSchema),
  parameters: z.array(ParameterSchema),
  quick_reply: QuickReplySchema.optional(),
  /** UI related field */
  customBlocks: z.array(z.boolean()),
  activeBlockIndices: z.array(z.number()),
});
const ImageHeroSchema = z.object({
  type: z.literal('image'),
  url: z.string(),
  key: z.string().optional(),
  size: z.literal('full').optional(),
  animated: z.boolean().optional(),
  aspectMode: z.enum(['cover', 'fit']).optional(),
  aspectRatio: z.string().optional(),
  action: z
    .discriminatedUnion('type', [
      z.object({
        type: z.literal('none'),
      }),
      MessageActionSchema,
      UriActionSchema,
    ])
    .optional(),
});

export type ImageHero = z.infer<typeof ImageHeroSchema>;

const CardFooterContentSchema = z.object({
  type: z.literal('button'),
  key: z.string(),
  action: z.discriminatedUnion('type', [
    z.object({
      type: z.literal('message'),
      text: createStringSchema({ required: true, max: 200 }),
      label: createStringSchema({ required: true, max: MAX_BUTTON_LABEL_LENGTH }),
    }),
    z.object({
      type: z.literal('uri'),
      uri: z.string(),
      label: createStringSchema({ required: true, max: MAX_BUTTON_LABEL_LENGTH }),
    }),
  ]),
});

export type CardFooterContent = z.infer<typeof CardFooterContentSchema>;

const CardContentSchema = z.object({
  type: z.literal('bubble'),
  hero: ImageHeroSchema.optional(),
  body: z.object({
    type: z.literal('box'),
    layout: z.literal('vertical'),
    contents: z.array(
      z.object({
        type: z.literal('text'),
        text: createStringSchema({ required: true, max: 1600 }),
        wrap: z.boolean().optional(),
        color: z.string().optional(),
        weight: z.string().optional(),
        offsetTop: z.string().optional(),
        action: LineMessageActionSchema.optional(),
      }),
    ),
  }),
  footer: z.object({
    type: z.literal('box'),
    layout: z.literal('vertical'),
    spacing: z.string().optional(),
    contents: z.array(CardFooterContentSchema),
  }),
  direction: z.literal('ltr'),
});

export type CardContent = z.infer<typeof CardContentSchema>;

const CardModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.card),
  data: z.object({
    notification_text: createStringSchema({ required: true, max: 300 }),
    contents: CardContentSchema,
  }),
  actions: z.array(z.object({})).optional(),
  parameters: z.array(ParameterSchema),
  quick_reply: QuickReplySchema.optional(),
});

const CarouselModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.carousel),
  data: z.object({
    notification_text: createStringSchema({ required: true, max: 300 }),
    contents: z.array(
      z.object({
        contents: CardContentSchema,
      }),
    ),
  }),
  actions: z.array(z.object({})).optional(),
  parameters: z.array(ParameterSchema),
  quick_reply: QuickReplySchema.optional(),
});

const PrizeModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.prize),
  data: z.object({
    notification_text: createStringSchema({ required: true, max: 300 }),
    contents: z.object({
      size: z.literal('mega').optional(),
      type: z.literal('bubble'),
      header: z.object({
        alignItems: z.literal('center'),
        contents: z.tuple([FlexImageSchema]),
        height: z.string(),
        layout: z.literal('vertical'),
        paddingAll: z.string(),
        spacing: FlexBoxSpacingSchema,
        type: z.literal('box'),
      }),
      body: z.object({
        alignItems: z.literal('center'),
        contents: z.tuple([
          z.object({
            contents: z.tuple([
              z.object({
                align: z.literal('center'),
                color: z.string().optional(),
                margin: FlexTextMarginSchema.optional(),
                size: z.literal('xl').optional(),
                text: z.string(),
                type: z.literal('text'),
                weight: FlexTextWeightSchema.optional(),
                wrap: z.boolean().optional(),
              }),
            ]),
            layout: z.literal('vertical'),
            paddingEnd: z.string().optional(),
            paddingStart: z.string().optional(),
            type: z.literal('box'),
          }),
          z.object({
            action: z.object({
              label: z.string(),
              type: z.literal('uri'),
              uri: z.string(),
            }),
            alignItems: z.literal('center'),
            background: z
              .object({
                angle: z.string(),
                endColor: z.string(),
                startColor: z.string(),
                type: z.literal('linearGradient'),
              })
              .optional(),
            contents: z.tuple([
              z.object({
                align: z.literal('center'),
                color: z.string().optional(),
                decoration: z.string().optional(),
                margin: FlexTextMarginSchema.optional(),
                size: z.literal('lg').optional(),
                text: z.string(),
                type: z.literal('text'),
                weight: FlexTextWeightSchema.optional(),
              }),
            ]),
            cornerRadius: z.string().optional(),
            layout: z.literal('vertical'),
            margin: FlexTextMarginSchema.optional(),
            paddingAll: z.string().optional(),
            spacing: FlexBoxSpacingSchema,
            type: z.literal('box'),
            width: z.string().optional(),
          }),
          z.object({
            align: z.literal('center'),
            color: z.string(),
            margin: FlexTextMarginSchema.optional(),
            size: FlexTextSizeSchema.optional(),
            text: z.string(),
            type: z.literal('text'),
            wrap: z.boolean().optional(),
          }),
        ]),
        justifyContent: FlexBoxJustifyContentSchema.optional(),
        layout: z.literal('vertical'),
        paddingEnd: z.string().optional(),
        paddingStart: z.string().optional(),
        type: z.literal('box'),
      }),
    }),
  }),
  format: z.object({
    prizeName: z.string(),
  }),
  parameters: z.array(ParameterSchema),
  quick_reply: QuickReplySchema.optional(),
});

const NewsModuleSchema = z.object({
  module_id: z.literal(MESSAGE_MODULE_TYPE.news),
  data: z.object({
    notification_text: createStringSchema({ required: true, max: 300 }),
    contents: z.object({
      body: z.object({
        type: z.literal('box'),
        layout: z.literal('vertical'),
        contents: FlexBoxCustomSchema.array(),
        paddingAll: z.string(),
      }),
      size: z.literal('giga').optional(),
      type: z.literal('bubble'),
      header: z.object({
        type: z.literal('box'),
        layout: z.literal('vertical'),
        contents: FlexBoxCustomSchema.array(),
        paddingAll: z.string(),
      }),
    }),
  }),
  format: z.object({
    rss: createStringSchema({ required: true, pattern: { isUrl: true } }),
    label: z.object({
      text: z.string(),
      backgroundColor: z.string(),
    }),
  }),
  parameters: z.array(ParameterSchema),
  quick_reply: QuickReplySchema.optional(),
});

export const MessageSchema = z.discriminatedUnion('module_id', [
  SmsModuleSchema,
  FlexModuleSchema,
  TextModuleSchema,
  ImageModuleSchema,
  ImageCarouselModuleSchema,
  VideoModuleSchema,
  ImagemapModuleSchema,
  ImagemapFlexModuleSchema,
  ImagemapCarouselModuleSchema,
  CardModuleSchema,
  CarouselModuleSchema,
  PrizeModuleSchema,
  NewsModuleSchema,
]);

export type Message = z.infer<typeof MessageSchema>;

export const LineMessageEditorSchema = z.object({
  messages: z.array(MessageSchema).default([]),
  quick_reply: QuickReplySchema.optional(),
});

export type LineMessageEditorInput = z.infer<typeof LineMessageEditorSchema>;
export type SmsModule = z.infer<typeof SmsModuleSchema>;
export type FlexModule = z.infer<typeof FlexModuleSchema>;
export type TextModule = z.infer<typeof TextModuleSchema>;
export type ImageModule = z.infer<typeof ImageModuleSchema>;
export type ImageCarouselModule = z.infer<typeof ImageCarouselModuleSchema>;
export type VideoModule = z.infer<typeof VideoModuleSchema>;
export type ImagemapModule = z.infer<typeof ImagemapModuleSchema>;
export type ImagemapFlexModule = z.infer<typeof ImagemapFlexModuleSchema>;
export type ImagemapCarouselModule = z.infer<typeof ImagemapCarouselModuleSchema>;
export type CardModule = z.infer<typeof CardModuleSchema>;
export type CarouselModule = z.infer<typeof CarouselModuleSchema>;
export type PrizeModule = z.infer<typeof PrizeModuleSchema>;
export type NewsModule = z.infer<typeof NewsModuleSchema>;
export type QuickReply = z.infer<typeof QuickReplySchema>;
