import { z } from 'zod';

import type { ZodiosResponseByAlias } from '@zodios/core';

import { makeApi } from 'shared/lib/zodios/makeApi';
import { PageResponseSchema } from 'shared/models/common';
import { TagItemSchema } from 'shared/models/tag/item';
import { TagMetadataItemSchema } from 'shared/models/tag/metadata';

const TagCreateParametersSchema = TagItemSchema.pick({
  name: true,
  available_days: true,
});

const TagCreateResponseSchema = TagItemSchema.pick({
  id: true,
  name: true,
  available_days: true,
  member_count: true,
});

const TagUpdateParametersSchema = TagItemSchema.pick({
  id: true,
  name: true,
  available_days: true,
});

const { apiDefinition, apiHooks } = makeApi('tag', [
  {
    alias: 'list',
    method: 'get',
    path: '/tag/v2/tag/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
      {
        name: 'is_permanent',
        schema: z.boolean().optional(),
        type: 'Query',
      },
      {
        name: 'name_q',
        schema: z.string().optional(),
        type: 'Query',
      },
      {
        name: 'page_number',
        schema: z.number().int().optional(),
        type: 'Query',
      },
      // Note: this defaults to `100` and also cannot exceed `100`
      {
        name: 'page_size',
        schema: z.number().int().max(100).optional(),
        type: 'Query',
      },
    ],
    response: PageResponseSchema.extend({
      results: TagItemSchema.strict().array(),
    }),
  },
  {
    alias: 'listMetrics',
    method: 'get',
    path: '/tag/v1/tag/metric/',
    parameters: [
      {
        name: 'bot',
        schema: z.number().int(),
        type: 'Query',
      },
    ],
    response: z
      .object({
        all: z.number().int(),
        is_period: z.number().int(),
        is_permanent: z.number().int(),
      })
      .strict(),
  },
  {
    alias: 'metadata',
    method: 'get',
    path: '/tag/v1/tag/raw_tags/',
    response: TagMetadataItemSchema.strict().array(),
  },
  {
    alias: 'create',
    method: 'post',
    path: '/tag/v1/tag/',
    parameters: [
      {
        name: 'body',
        schema: TagCreateParametersSchema.strict(),
        type: 'Body',
      },
    ],
    response: TagCreateResponseSchema.strict(),
  },
  {
    alias: 'update',
    method: 'patch',
    path: '/tag/v1/tag/:tagId/',
    parameters: [
      {
        name: 'body',
        schema: TagUpdateParametersSchema.strict(),
        type: 'Body',
      },
    ],
    response: TagItemSchema.strict(),
  },
  {
    alias: 'delete',
    method: 'delete',
    path: '/tag/v1/tag/:tagId/',
    response: z.never(),
  },
]);

export type TagCreateResponse = ZodiosResponseByAlias<typeof apiDefinition, 'create'>;

export { apiHooks as tagApiHooks };
